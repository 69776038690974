import React from 'react'
import clsx from 'clsx';
import { Button } from '../../../theme/MUIImports';
import propTypes from 'prop-types'
import { useStyles } from './styles';

export const CustomButton = ({ variant, src, text, onClick, color, disabled, type, className, sx, endIcon, startIcon }) => {
  const classes = useStyles();
  const classCollection = {
    'btn-left-img': clsx(classes.button, classes.voidButton),
    'primary': clsx(classes.button, classes.primaryButton),
    'btn-secondary-small': clsx(classes.button, classes.secondaryButtonSmall),
    'btn-secondary-medium': clsx(classes.button, classes.secondaryButtonmedium),
    'btn-primary-small': clsx(classes.button, classes.primaryButtonSmall),
    'btn-primary-outlined': classes.voidButtonRoundedPill,
    'btn-secondary-contained': clsx(classes.button, classes.buttonGreyPill),
    'btn-img': clsx(classes.button, classes.buttonOutLineImage),
    'btn-prev-page': classes.backButton
  }

  return (
    <Button
      disabled={disabled}
      type={type}
      className={classCollection[variant]}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      color={color}
      sx={sx}
      onClick={onClick}>
      {src ? <img className={classes.btnIcon} src={src} alt={src} /> : ''}
      {text}</Button>
  )
}

CustomButton.defaultProps = {
  text: "",
  color: "primary",
  disabled: false,
  type: "button",
  variant: "",
  src: "",
  onClick: () => null,
  className: '',
  sx: {},
  endIcon: <></>,
  startIcon: <></>
}

CustomButton.propTypes = {
  variant: propTypes.string,
  src: propTypes.string,
  text: propTypes.oneOfType([propTypes.string, propTypes.element]),
  onClick: propTypes.func,
  color: propTypes.string,
  disabled: propTypes.bool,
  type: propTypes.string,
  className: propTypes.string,
  sx: propTypes.object,
  endIcon: propTypes.element,
  startIcon: propTypes.element
}