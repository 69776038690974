
import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    // Font Color
    textLight: {
        color: theme.palette.light.main,
    },
    textDark: {
        color: theme.palette.primary.main,
    },
    textBlack:  {
        color:  '#212121'
    },
    // Font Size
    text16: {
        fontSize: '16px',
    },
    text18: {
        fontSize: '18px !important',
    },
    text20: {
        fontSize: '20px !important',
    },
    text24: {
        fontSize: '24px !important',
    },
    text32: {
        fontSize: '32px !important',
    },
    // Font Weight
    textBold: {
        fontWeight: '700 !important'
    },
    textSemiBold: {
        fontWeight: '600 !important'
    },
    textMedium: {
        fontWeight: '500 !important'
    },
    textRegular: {
        fontWeight: '400 !important'
    },
    // Font Alignment
    textCenter: {
        textAlign: 'center !important'
    },
    widthLimit: {
        maxWidth: 300,
    }
}));