import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    breadCrumb: {
        '& .MuiBreadcrumbs-ol': {
            '& .MuiBreadcrumbs-li': {
                maxWidth: 210,
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }
        }
    },
    breadcrumbsLink: {
        fontSize: 16,
        fontWeight: '600',
        color: '#838395 !important',
        cursor: 'pointer',
        textTransform: 'none',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        whiteSpace:'nowrap'
    },
    breadcrumbsLinkActive: {
        fontSize: 16,
        fontWeight: '700 !important',
        color: '#31314F !important',
        cursor: 'pointer',
        textTransform: 'none',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        whiteSpace:'nowrap'
    }
}));