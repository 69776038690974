import React from 'react'
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { MuiDrawer, styled, Box } from '../../../theme/MUIImports';
import { AlertDialog, Button, CreateDomainForm } from '../..';
import { AdminSideMenus } from './admin/AdminSideMenus';
import { UserSideMenu } from './user/UserSideMenu';
import { useStyles } from './SidebarStyles';
import { ManagerSideMenus } from './manager';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, drawerwidth }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerwidth,
      backgroundColor: theme.palette.primary.main,
      height: "100vh",
      boxSizing: 'border-box',
    },
  }),
);

export const Sidebar = ({ open, drawerWidth, navigate, location, role, handleOpenSnackMessage }) => {
  const classes = useStyles()
  const { sideMenu } = useSelector(state => state.user)

  const [isOpenPopup, setOpenPopup] = React.useState({
    open: false,
    children: null,
    handleNo: null
  })

  //close enter domain and jira ticket details popup
  const closeCreateJiraTicketPopup = () => setOpenPopup({ ...isOpenPopup, open: false })

  //open enter domain and jira ticket details popup
  const openCreateJiraTicketPopup = () => setOpenPopup({
    ...isOpenPopup, open: true, handleNo: closeCreateJiraTicketPopup,
    children: <CreateDomainForm navigate={navigate} location={location} handleOpenSnackMessage={handleOpenSnackMessage} handleClose={closeCreateJiraTicketPopup} />
  })

  const renderSidebarComponent = () => {
    switch (role) {
      case 'user':
        return <UserSideMenu navigate={navigate} location={location} openCreateJiraTicketPopup={openCreateJiraTicketPopup} handleOpenSnackMessage={handleOpenSnackMessage} />
      case 'admin':
        return <AdminSideMenus navigate={navigate} location={location} openCreateJiraTicketPopup={openCreateJiraTicketPopup} handleOpenSnackMessage={handleOpenSnackMessage} />
      case 'manager':
        return <ManagerSideMenus navigate={navigate} location={location} openCreateJiraTicketPopup={openCreateJiraTicketPopup} handleOpenSnackMessage={handleOpenSnackMessage} />

      default:
        return ""
    }
  }


  return (
    <Drawer variant="permanent" open={open} className={classes.sidebar} drawerwidth={drawerWidth}>
      <Box className={classes.sidebarTop} display='flex' flexDirection='column'>
        <img src={`${process.env.REACT_APP_AWS_IMAGE_BASE_URL}/${process.env.REACT_APP_S3_BUCKET_VERSION}/mecha-logo.png`} alt="sidebar-logo" />
        <Button variant="btn-primary-outlined" onClick={() => openCreateJiraTicketPopup()} text="Enter JIRA Ticket Info" />
      </Box>
      <AlertDialog isOpenPopup={isOpenPopup} showCloseButton={Boolean(sideMenu?.length)} />
      {renderSidebarComponent()}
    </Drawer>)
}


Sidebar.propTypes = {
  open: propTypes.bool,
  toggleDrawer: propTypes.func,
  drawerWidth: propTypes.number,
  navigate: propTypes.func,
  location: propTypes.object,
  role: propTypes.string
};

Sidebar.defaultProps = {
  open: false,
  toggleDrawer: () => null,
  drawerWidth: 240,
  navigate: () => null,
  location: {},
  role: ""
};
