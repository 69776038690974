import { makeStyles } from "../../../theme/MUIImports";

export const useStyles = makeStyles((theme) => ({
    Dialog: {
        '& .MuiPaper-root.MuiDialog-paper': {
            maxWidth: 600,
            width   : 'calc(100% - 64px)',
            borderRadius: 12,
        },
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgb(0 0 0 / 90%)'
        }
    }
}));
