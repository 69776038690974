 
import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    tabs: {
        width: '100%',
        bgcolor: 'background.paper',
        '& button.MuiTab-root' : {
            '& .MuiBox-root':{

                '& span' : {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '25rem'
                }
            }
        }
    },
  
    tabHead:{
        maxWidth: '100% !important', 
        textTransform: 'none !important',
         color: 'white !important', 
         backgroundColor: theme.palette.primary.main + '!important'
    }

}))