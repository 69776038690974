
export const userPermissions = {
    roles: [
        {
            role: 'Admin',
            permissions: [
                { url: '/query-editor', title: "Query Editor" },
                { url: '/view-queries', title: "Queries" },
                { url: '/existing-users', title: "Existing Users" },
                { url: '/user-request', title: "New Users" },
                { url: '/user-details', title: "" },
                { url: '/query-history', title: "Query History" },
                { url: '/user-activities', title: "User Activities" },
                { url: '/my-requests', title: "My Requests" }
            ]

        },
        {
            role: 'User',
            permissions: [
                { url: '/query-editor', title: "Query Editor" },
                { url: '/view-queries', title: "Queries" },
                { url: '/my-requests', title: "My Requests" }
            ]
        },
        {
            role: 'Manager',
            permissions: [
                { url: '/query-editor', title: "Query Editor" },
                { url: '/view-queries', title: "Queries" },
                { url: '/query-history', title: "Query History" },
                { url: '/user-activities', title: "User Activities" },
                { url: '/query-requests', title: "REQUEST MANAGEMENT" },
                { url: '/query-requests-details', title: "REQUEST MANAGEMENT DETAILS" },
                { url: '/my-requests', title: "My Requests" }
            ]
        }
    ]

}