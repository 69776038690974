import React from 'react'
import clsx from 'clsx';
import propTypes from 'prop-types'
import { useStyles } from './styles';
import { Tooltip, Typography } from '../../../theme/MUIImports';

export const CustomTypography = ({ variant, text, className, align, noWrap, paragraph, sx, tooltipProps ,component}) => {
    const classes = useStyles();
    const variantClasses = {
        'subtitle-semi-bold-16': clsx(classes.text16, classes.textSemiBold),
        'subtitle-semi-bold-nowrap-16': clsx(classes.text16, classes.textSemiBold, classes.widthLimit),
        'subtitle-bold-grey-16': clsx(classes.textLight, classes.text16, classes.textSemiBold),
        'subtitle-black-16': clsx(classes.textBlack, classes.text16, classes.textSemiBold),
        'subtitle-primary-20': clsx(classes.textDark, classes.text20, classes.textCenter),
        'title': clsx(classes.textDark, classes.text20, classes.textSemiBold),
        'title-32-primary': clsx(classes.textDark, classes.text32, classes.textBold, classes.textCenter),
        'button-text-medium': clsx(classes.textCenter),
    }

    const TypographyComponent = () => (<Typography
        variant={variant}
        className={variantClasses[className]}
        sx={sx}
        component={component}
        paragraph={paragraph}
        align={align}
        noWrap={noWrap}>{text}</Typography>)

    return (
        <>
            {tooltipProps?.open ? <Tooltip placement={tooltipProps.placement} title={text}>
                <Typography
                    component={component}
                    variant={variant}
                    className={variantClasses[className]}
                    sx={sx}
                    paragraph={paragraph}
                    align={align}
                    noWrap={noWrap}>{text}</Typography>
            </Tooltip>
                : <TypographyComponent />
            }
        </>
    )
}

CustomTypography.propTypes = {
    variant: propTypes.string,
    text: propTypes.oneOfType([propTypes.string, propTypes.element]),
    className: propTypes.string,
    align: propTypes.string,
    noWrap: propTypes.bool,
    paragraph: propTypes.bool,
    sx: propTypes.object,
    tooltipProps: propTypes.shape({
        placement: propTypes.string,
        open: propTypes.bool
    }),
    component:propTypes.string,
}

CustomTypography.defaultProps = {
    variant: "textLight",
    text: "",
    className: "",
    align: "inherit",
    noWrap: false,
    paragraph: false,
    sx: {},
    tooltipProps: { placement: 'top-start', open: false },
    component:'span'
}
