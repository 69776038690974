 
import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
  // Search Input
  inputGroup: {
    position: 'relative',
    '& img': {
      width: 24,
      minWidth: 24,
      position: 'absolute',
      left: 8,
      marginTop: '12px !important',
    },
    '& .MuiFormControl-root': {
      // INPUT LABEL
      '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl ': {
        color: '#6F6F84',
        fontSize: 14,
        fontWeight: '500',
        left: 22,
        maxWidth: 'calc(100% - 46px)',
      },
      '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink ': {
        left: 0,
      },
      // INPUT FIELD
      '& .MuiInputBase-root.MuiInputBase-formControl': {
        borderRadius: 0,
        '& .MuiInputBase-input': {
          fontSize: 14,
          height: 17,
          fontWeight: '500',
          paddingLeft: 36,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#D9D9D9',
          color: '#6F6F84',
          fontSize: 14,
          fontWeight: '500'
        }
      }
    },
  },
  table: {
    width: '100%',
    height: '100%',
    '& .ag-theme-material': {
      '--ag-material-accent-color': theme.palette.primary.main,
      width: '100%',
      '& .ag-layout-auto-height': {
        // minHeight: '46vh',
      },
      '& .ag-root-wrapper': {
        backgroundColor: 'white',
        '& .ag-root-wrapper-body': {
          '& .ag-root': {
            '& .ag-body-viewport': {
              '& .ag-center-cols-clipper': {
                '& .ag-center-cols-viewport': {
                  '& .ag-center-cols-container': {
                    '& .ag-row': {
                      '& .ag-cell': {
                        display: 'flex',
                        alignItems: 'center',
                        '& span': {
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },

      '& .ag-root.ag-unselectable': {
        // Header
        '& .ag-header.ag-pivot-off': {
          minHeight: '48px !important',
          height: '48px !important',
          '& .ag-header-row.ag-header-row-column': {
            height: '48px !important',
            backgroundColor: '#31314F',
            '& .ag-header-cell.ag-focus-managed': {
              color: '#ffffff',
              fontSize: '14px',
              fontWeight: '700',
              '& .ag-header-cell-menu-button:not(.ag-header-menu-always-show)': {
                opacity: '1'
              },
              '& .ag-cell-label-container': {
                '& .ag-header-icon': {
                  color: '#ffffff',
                },
                '& .ag-icon-filter': {
                  display: 'none'
                }
              },
              '&:hover': {
                backgroundColor: 'inherit'
              },
            }
          }
        },
        // body
        '& .ag-body-viewport.ag-row-no-animation.ag-layout-auto-height': {
          '& .ag-center-cols-viewport': {
            '& .ag-row-no-focus.ag-row.ag-row-position-absolute, .ag-row-selected, .ag-row-focus': {
              color: '#6F6F84',
              fontSize: '15px',
              fontWeight: '700',
              border: '1px solid #D6D6DC',
              borderTop: 'none',
            }
          }
        }
      },
      // pagination 
      '& .ag-paging-panel.ag-unselectable': {
        justifyContent: 'space-between',
        '& .ag-paging-row-summary-panel': {
          fontWeight: 600,
          fontSize: 13,
          lineHeight: '16px',
          color: '#8B8B94',
        },
        '& .ag-paging-page-summary-panel': {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '17px',
          color: '#8686B7',
        },
        '& .ag-paging-button .ag-icon': {
          fontWeight: 600,
          fontSize: 16,
          lineHeight: '17px',
          color: '#8686B7',
        }
      }
    },
    '& .ag-popup': {
      width: 'auto',
      height: 'auto'
    }
  },
  tableHeaderAction: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  contentLeftCenter: {
    display: 'flex',
    alignItems: 'center'
  },
  resetButtonSpace : {
    marginRight: 24,
  }
}));