import * as React from 'react';
import propTypes from 'prop-types';
import { Box, Typography, Tabs, Tab, Tooltip } from "../../../theme/MUIImports"
import { useStyles } from './style';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other} >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: propTypes.node,
    index: propTypes.number.isRequired,
    value: propTypes.number.isRequired,
};

TabPanel.defaultProps = {
    children: <></>,
    index: 0,
    value: 0,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const CustomTabs = ({ content, activeIndex, handleChangeScreen }) => {
    const classes = useStyles();
    return (
        <Box className={classes.tabs}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={activeIndex}
                    indicatorColor="primary"
                    textColor="inherit"
                    onChange={handleChangeScreen} aria-label="basic tabs example">
                    {!!content?.length
                        && content?.map((header, index) =>
                            <Tab key={`${index} - ${header.label}`} className={classes.tabHead} label={<Box display="flex" gap={2}>
                                <Tooltip title={header.label}>
                                    <span className={classes.label}>{header.label}</span>
                                </Tooltip>
                                {header?.label2 ? " | " : ""}
                                <Tooltip title={header?.label2}>
                                    <span style={{ maxWidth: '22rem !important' }}>{header?.label2}</span>
                                </Tooltip>
                            </Box>
                            } {...a11yProps(index)} />
                        )}
                </Tabs>
            </Box>
            {!!content?.length
                && content?.map((body, index) =>
                    <TabPanel key={`${index} - ${body.label}`} value={activeIndex} index={index}>
                        {body.children}
                    </TabPanel>)}
        </Box>
    );
}

CustomTabs.propTypes = {
    content: propTypes.array,
    activeIndex: propTypes.number,
    handleChangeScreen: propTypes.func
}

CustomTabs.defaultProps = {
    content: [
        { id: 0, label: "Item One", children: <>Item One</> },
        { id: 1, label: "Item Two", children: <>Item Two</> },
        { id: 2, label: "Item Three", children: <>Item Three</> },
    ],
    activeIndex: 0,
    handleChangeScreen: () => null
}
