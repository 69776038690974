import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types';
import { ArrowRightIcon, ArrowDropDownIcon, DashboardIcon, BugReportIcon } from '../../../../theme/MUIIcons'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText,  Tooltip } from "../../../../theme/MUIImports"
import { actionChooseJiraTicket, actionGetDomainList } from '../../../../actions/user'
import { useStyles } from '../menuStyles';
import { Loader } from '../../..';
import staticMenu from "./user.json"

export const UserSideMenu = ({ handleOpenSnackMessage, openCreateJiraTicketPopup, location, navigate }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    let { sideMenu, chooseDomainLoader } = useSelector(state => state.user)
    const { userData } = useSelector((state) => state.auth)
    const { _id, history } = userData;
    const [menuList, setMenuList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState({
        menuIndex: 0,
        subMenuIndex: 0
    })

    /*API action call to get Domain list*/
    const getDomainList = useCallback(async () => {
        const res = await dispatch(actionGetDomainList({ userId: _id, limit: 10 }));
        if (res.status && res?.total === 0) {
            openCreateJiraTicketPopup()
        }
         //eslint-disable-next-line
    }, [_id])

    const handleExpandSideMenu = (e, index) => {
        const newMenu = [...menuList];
        if (newMenu[index]?.staticMode) {
            navigate('/my-requests')
        } else {
            newMenu[index].isExpand = !newMenu[index].isExpand;
            setMenuList(newMenu);
        }
    }

    /* API call to choose a ticket*/
    const handleChooseTicket = async (e, index, subIndex) => {
        const res = await dispatch(actionChooseJiraTicket({
            domain_id: menuList[index]?._id,
            ticket_id: menuList[index].tickets[subIndex]?._id
        }))
        if (res.status) {
            setSelectedIndex({ ...selectedIndex, menuIndex: index, subMenuIndex: subIndex })
            handleOpenSnackMessage({ open: true, type: "success", message: res?.message });
            location.pathname !== '/query-editor' && navigate('/query-editor')
        } else {
            handleOpenSnackMessage({ open: true, type: "error", message: res?.message });
        }

    }

    React.useEffect(() => {
        getDomainList()
    }, [getDomainList])

    React.useEffect(() => {
        if (sideMenu?.length || sideMenu?.length !== menuList?.length) {
            const allMenus = [...staticMenu, ...sideMenu]
            const newMenu =
                allMenus?.length && allMenus?.map((menu) => ({
                    ...menu,
                    icon: DashboardIcon,
                    isExpand: false,
                    tickets: menu?.tickets?.length && menu?.tickets?.map((subMenu) => ({
                        ...subMenu,
                        icon: BugReportIcon,
                    }))
                }))
            setMenuList(newMenu)
        }
        return () => {
            setMenuList([]);
        }
         //eslint-disable-next-line
    }, [sideMenu])

    React.useEffect(() => {
        if (history?.length && menuList?.length) {
            const selectActiveDomain = menuList?.length && menuList?.findIndex(menu => menu._id === history[0]?.domain_id);
            const selectActiveSubDomain = menuList?.length && menuList[menuList?.findIndex(menu => menu._id === history[0]?.domain_id)]?.tickets?.findIndex(subMenu => subMenu?._id === history[0]?.session_id);
            const isStaticPageSelected = menuList?.length && menuList?.findIndex(menu => menu?.staticMode === true && menu?.path === location?.pathname);
            setSelectedIndex({
                menuIndex: isStaticPageSelected !== -1 ? isStaticPageSelected : selectActiveDomain,
                subMenuIndex: selectActiveSubDomain
            })
        }
         //eslint-disable-next-line
    }, [history, menuList, location])

    return (
        <List className={classes.userMenu} component="nav" data-testid="sidebar-menu">
            <Loader open={chooseDomainLoader} />
            {!!menuList?.length &&
                menuList?.map((option, index) =>
                    <React.Fragment key={option._id}>
                        <Tooltip placement="right-start" title={option?.domain_name}>
                            <ListItemButton
                                disabled={chooseDomainLoader}
                                selected={index === selectedIndex.menuIndex}
                                onClick={(e) => handleExpandSideMenu(e, index)}>
                                <ListItemIcon>
                                    <option.icon />
                                </ListItemIcon>
                                <ListItemText primary={option?.domain_name} />
                                {!!option.tickets?.length && <React.Fragment>{option?.isExpand ? <ArrowDropDownIcon />
                                    : <ArrowRightIcon />}
                                </React.Fragment>}
                            </ListItemButton>
                        </Tooltip>
                        <Collapse in={option?.isExpand} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding className={classes.subMenus}>
                                {!!option?.tickets?.length &&
                                    option.tickets?.map((subOption, subMenuIndex) =>
                                        <ListItemButton
                                            key={subOption?._id}
                                            disabled={chooseDomainLoader}
                                            selected={index === selectedIndex.menuIndex && subMenuIndex === selectedIndex.subMenuIndex}
                                            onClick={(e) => handleChooseTicket(e, index, subMenuIndex)}
                                            sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <subOption.icon />
                                            </ListItemIcon>
                                            <Tooltip placement="right-start" title={subOption.ticket_url}><ListItemText primary={subOption.ticket_url} /></Tooltip>
                                        </ListItemButton>)}
                            </List>
                        </Collapse>
                    </React.Fragment>
                )}
        </List>
    )
}

UserSideMenu.propTypes = {
    handleOpenSnackMessage: propTypes.func,
    openCreateJiraTicketPopup: propTypes.func,
    navigate: propTypes.func,
    location: propTypes.object
}
UserSideMenu.defaultProps = {
    handleOpenSnackMessage: () => ({ open: false, type: "error", message: "" }),
    openCreateJiraTicketPopup: () => null,
    navigate: () => null,
    location: {}
}