import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import propTypes from 'prop-types';
import { ArrowRightIcon, ArrowDropDownIcon, DashboardIcon, BugReportIcon, DnsIcon, FeedIcon } from '../../../../theme/MUIIcons'
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "../../../../theme/MUIImports"
import { actionChooseJiraTicket, actionGetDomainList } from '../../../../actions/user'
import { useStyles } from '../menuStyles';
import { Loader } from '../../..';
import adminList from "./manager.json"

export const ManagerSideMenus = ({ handleOpenSnackMessage, openCreateJiraTicketPopup, navigate, location }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  let { sideMenu, chooseDomainLoader } = useSelector(state => state.user)
  const { userData } = useSelector((state) => state.auth)
  const { _id, history, role } = userData;
  const [menuList, setMenuList] = useState([])
  const [selectedIndex, setSelectedIndex] = useState({
    menuIndex: 0,
    subMenuIndex: 0,
    childIndex: 0
  })

  /*API action call to get Domain list*/
  const getDomainList = useCallback(async () => {
    const res = await dispatch(actionGetDomainList({ userId: _id, limit: 10 }));
    if (res.status && res?.total === 0 && role !== 'Admin')
      openCreateJiraTicketPopup();
     //eslint-disable-next-line
  }, [_id, role])

  // expand main menu
  const handleExpandSideMenu = (index) => {
    if (menuList[index]['staticMode']) {
      navigate(menuList[index]?.path)
    }
    const newMenu = [...menuList];
    newMenu[index].isExpand = !newMenu[index].isExpand;
    setMenuList(newMenu)
  }

  /* API call to choose a ticket*/
  const handleOpenSubMenu = async (index, subIndex) => {
    if (!menuList[index].tickets[subIndex]?.static) {
      const newMenu = [...menuList];
      menuList[index].tickets[subIndex].isExpand = !menuList[index].tickets[subIndex].isExpand;
      setMenuList(newMenu)
    } else {
      setSelectedIndex({
        menuIndex: index,
        subMenuIndex: subIndex
      })
      navigate(menuList[index].tickets[subIndex]?.path)
    }
  }

  const handleChooseTicket = async (index, subMenuIndex, childMenuIndex) => {
    const newmenuList = [...menuList];
    const selectedTicket = newmenuList[index]?.tickets[subMenuIndex]?.tickets[childMenuIndex] || {};
    const res = await dispatch(actionChooseJiraTicket({
      domain_id: selectedTicket?.domain_id,
      ticket_id: selectedTicket?._id
    }))
    if (res.status) {
      setSelectedIndex({ ...selectedIndex, menuIndex: index, subMenuIndex: subMenuIndex, childIndex: childMenuIndex })
      handleOpenSnackMessage({ open: true, type: "success", message: res?.message });
      location.pathname !== '/query-editor' && navigate('/query-editor')
    } else {
      handleOpenSnackMessage({ open: true, type: "error", message: res?.message });
    }
  }

  const getActiveSubMenu = (index, subMenuIndex) => Boolean(index === selectedIndex.menuIndex && subMenuIndex === selectedIndex.subMenuIndex);

  const getActiveMenu = (index) => Boolean(index === selectedIndex.menuIndex);

  React.useEffect(() => {
    getDomainList()
  }, [getDomainList])

  React.useEffect(() => {
    if (sideMenu?.length || sideMenu?.length !== menuList?.length) {
      const newSideMenu = [...adminList];
      const newMenu = newSideMenu?.length && newSideMenu?.map((menu, index) => ({
        ...menu,
        icon: DashboardIcon,
        isExpand: false,
        tickets: menu._id === 'QUERY-TOOL-ID' ? sideMenu?.length && sideMenu?.map((subMenu) => ({
          ...subMenu,
          icon: DnsIcon,
          ticket_url: subMenu?.domain_name,
          isExpand: false,
          tickets: subMenu?.tickets?.length && subMenu?.tickets?.map((childMenu) => ({
            ...childMenu,
            icon: BugReportIcon,
          }))
        })) : menu?.tickets?.length && menu?.tickets?.map((subMenu) => ({
          ...subMenu,
          icon: FeedIcon,
        }))
      }))
      setMenuList(newMenu)
    }
    return () => {
      setMenuList([]);
    }
     //eslint-disable-next-line
  }, [sideMenu, adminList])

  React.useEffect(() => {
    if (history?.length && menuList?.length) {
      // if static path is selected path menu is actived with respect to url
      const newMenuList = [...menuList];
      const selectedMenu = (newMenuList?.length && newMenuList.filter(menu => menu?.isMainMenu))?.find((subMenu) => subMenu?.path === location.pathname) || {}
      if (Object.keys(selectedMenu)?.length) {
        // check top listed menu item is active
        const selectedIndex = newMenuList?.length && newMenuList?.findIndex((menu) => menu.path === selectedMenu.path);
        // check second level of menu item is active
        const subSelectedIndex = (newMenuList?.length && newMenuList[selectedIndex]?.tickets?.length &&
          newMenuList[selectedIndex]?.tickets?.findIndex(menu => menu._id === history[0]?.domain_id));
        // check third level of menu item is active
        const selectActiveSubDomain = (newMenuList?.length && newMenuList[selectedIndex]?.tickets?.length &&
          newMenuList[selectedIndex]?.tickets[subSelectedIndex]?.tickets?.length &&
          newMenuList[selectedIndex]?.tickets[subSelectedIndex]?.tickets?.findIndex(subMenu => subMenu?._id === history[0]?.session_id))
        setSelectedIndex({ ...selectedIndex, menuIndex: selectedIndex, subMenuIndex: subSelectedIndex, childIndex: selectActiveSubDomain })
      } else {
        // check top listed menu item is active
        const menuIndex = (newMenuList?.length && newMenuList.map(menu => ({
          ...menu,
          tickets: menu?.tickets?.filter(subMenu => subMenu.path === location.pathname)
        }))?.findIndex(menu => menu.tickets?.length));
        // check second level of menu item is active
        const subMenuIndex = (newMenuList?.length && newMenuList.map(menu => ({
          ...menu,
          tickets: menu?.tickets?.findIndex(subMenu => subMenu.path === location.pathname)
        })).find(menu => menu.tickets !== -1)?.tickets);
        setSelectedIndex({ ...selectedIndex, menuIndex: menuIndex, subMenuIndex: subMenuIndex, childIndex: 0 })
      }
    }
     //eslint-disable-next-line
  }, [history, menuList, location?.pathname])


  return (<List component="nav" className={classes.userMenu} data-testid="admin-sidebar-menu">
    <Loader open={chooseDomainLoader} />
    {!!menuList?.length &&
      menuList?.map((option, index) =>
        <React.Fragment key={option._id}>
          <Tooltip placement="right-start" title={option?.domain_name}>
            <ListItemButton
              disabled={chooseDomainLoader}
              selected={getActiveMenu(index)}
              onClick={() => handleExpandSideMenu(index)}>
              <ListItemIcon>
                <option.icon />
              </ListItemIcon>
              <ListItemText primary={option?.domain_name} />
              {!!option.tickets?.length && <React.Fragment>{option?.isExpand ? <ArrowDropDownIcon />
                : <ArrowRightIcon />}
              </React.Fragment>}
            </ListItemButton>
          </Tooltip>
          <Collapse in={option?.isExpand} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.subMenus}>
              {!!option?.tickets?.length &&
                option.tickets?.map((subOption, subMenuIndex) =>
                  <React.Fragment key={subOption._id}>
                    <Tooltip placement="right-start" title={subOption.ticket_url} key={subOption._id}>
                      <ListItemButton
                        disabled={chooseDomainLoader}
                        selected={getActiveSubMenu(index, subMenuIndex)}
                        onClick={() => handleOpenSubMenu(index, subMenuIndex)}
                        sx={{ pl: 4 }}>
                        <ListItemIcon>
                          <subOption.icon />
                        </ListItemIcon>
                        <ListItemText primary={subOption.ticket_url} />
                        {!!subOption.tickets?.length && <React.Fragment>{subOption?.isExpand ? <ArrowDropDownIcon />
                          : <ArrowRightIcon />}
                        </React.Fragment>}
                      </ListItemButton>
                    </Tooltip>
                    <Collapse in={subOption?.isExpand} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className={classes.childMenu}>
                        {!!subOption?.tickets?.length &&
                          subOption.tickets?.map((childOption, childMenuIndex) =>
                            <Tooltip placement="right-start" title={childOption.ticket_url} key={childOption._id}>
                              <ListItemButton
                                disabled={chooseDomainLoader}
                                selected={Boolean(selectedIndex.menuIndex && subMenuIndex === selectedIndex.subMenuIndex && childMenuIndex === selectedIndex.childIndex)}
                                onClick={() => handleChooseTicket(index, subMenuIndex, childMenuIndex)}
                                sx={{ pl: 4 }}>
                                <ListItemIcon>
                                  <childOption.icon />
                                </ListItemIcon>
                                <ListItemText primary={childOption.ticket_url} />
                              </ListItemButton>
                            </Tooltip>
                          )}
                      </List>
                    </Collapse>
                  </React.Fragment>
                )}
            </List>
          </Collapse>
        </React.Fragment>
      )}</List>)
}

ManagerSideMenus.propTypes = {
  handleOpenSnackMessage: propTypes.func,
  openCreateJiraTicketPopup: propTypes.func,
  navigate: propTypes.func,
  location: propTypes.object
}

ManagerSideMenus.defaultProps = {
  handleOpenSnackMessage: () => ({ open: false, type: "error", message: "" }),
  openCreateJiraTicketPopup: () => null,
  navigate: () => null,
  location: {}
}