import * as React from 'react';
import propTypes from 'prop-types';
import { Breadcrumbs, Link, Stack, Tooltip } from "../../../theme/MUIImports"
import { NavigateNextIcon } from '../../../theme/MUIIcons';
import { useStyles } from './style';

export const CustomBreadcrumbs = ({ breadCrumbList, selectBreadcrumb }) => {
    const classes = useStyles()
    return (
        <Stack spacing={2}>
            <Breadcrumbs maxItems={3} 
            className={classes.breadCrumb}
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb">
                {!!breadCrumbList?.length &&
                    breadCrumbList?.map((option, index) =>
                    <Tooltip placement='top-start' key={`${option?.title}-${index}`} title={option?.title}>
                        <Link
                            className={index === breadCrumbList?.length - 1 ? classes.breadcrumbsLinkActive : classes.breadcrumbsLink}
                            name={option?.type}
                            underline={index === breadCrumbList?.length - 1 ? "none" : "hover"}
                            key={String(option.key)}
                            color={index === breadCrumbList?.length - 1 ? "text.primary" : "inherit"}
                            onClick={(e) => selectBreadcrumb(e)}>
                            {option?.title}
                        </Link>
                        </Tooltip>
                    )}
            </Breadcrumbs>
        </Stack>
    );
}

CustomBreadcrumbs.propTypes = {
    breadCrumbList: propTypes.array,
    selectBreadcrumb: propTypes.func
}

CustomBreadcrumbs.defaultProps = {
    breadCrumbList: [{ title: "Breadcrumb item 1", key: 1 }, { title: "breadcrumb item 2", key: 2 }],
    selectBreadcrumb: () => null
}