import * as React from 'react';
import { makeStyles } from "../../../theme/MUIImports";
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: 'auto',
        '& .demoCustomizedButton' : {
            borderRadius: 26, 
            minHeight: 40, 
            backgroundColor: '#9797A6', 
            fontSize: 14, 
            fontWeight: '600', 
            textTransform: 'none'
        }
    }
}));

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 12,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '0',
        },
        '& .popup-head': {
            backgroundColor: '#ECECEC',
            padding: 4,
        },
        '& .popup-body': {
            backgroundColor: '#D9D9D9',
            padding: 8,
        },
        '& .MuiMenuItem-root': {
            backgroundColor: '#31314F',
            borderRadius: 26,
            margin: 8,
            color: '#ffffff',
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));