import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    menu: {
        width: 'auto',
        marginLeft:'auto'
    },
    appMenu: {
        width: 'auto',
        '& .MuiList-root': {
            position: 'relative',

        }

    },
    loader: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 'auto',
        right: 'auto',
        top: '0',
        bottom: '0',
        '& .MuiCircularProgress-root':{
            margin:'auto'
        }
    }
}));