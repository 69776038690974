const CryptoJS = require("crypto-js");
const key = process.env.REACT_APP_CRYPTO_JS_KEY

exports.encrypt = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

const IsJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

exports.decrypt = (data) => {
    if (data) {
        const bytes = CryptoJS.AES.decrypt(data, key);
        return IsJsonString(bytes.toString(CryptoJS.enc.Utf8)) ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : false
    } else {
        return false
    }
}