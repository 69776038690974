import { createTheme } from "./MUIImports"

export const lightTheme = createTheme(
    {
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: "#31314F"
            },
            secondary: {
                // This is green.A700 as hex.
                main: '#11cb5f',
            },
            light:  {
                // text color
                main: '#ADADB9'
            },
            textListColor: {
                main: '#727286'
            },
            loader:{
                primary:"#31314F"
            }
        },
    }
);

