import React from 'react';
import clsx from 'clsx';
import propTypes from 'prop-types';
import { Toolbar, Typography } from '../../../theme/MUIImports'
import { useStyles } from './styles';
import { Search } from '../Search';
import { SettingsMenu } from '../SettingsMenu';
import { Button, Link } from '../..';
import { useSelector } from 'react-redux';
import { Notifications } from '../Notifications';
import { userPermissions } from '../../../utils/userPermissions';


export const CustomAppBar = ({ location, handleOpenSnackMessage, navigate, socket }) => {
    const classes = useStyles();
    const { userDetails } = useSelector((state) => state.admin);
    const { userData } = useSelector((state) => state.auth);
    const { name } = userDetails;
    const { role } = userData;
    const getRoles = (!!userPermissions?.roles?.length && userPermissions?.roles?.find((newRole) => newRole.role === role)) || { permissions: [] }
    const { permissions } = getRoles;

    const getTitle = React.useCallback(() =>
        location?.pathname === '/user-details' ? name :
            ((!!permissions?.length && permissions?.find((permission) => permission?.url === location?.pathname)?.title) || '')
             //eslint-disable-next-line
        , [location, userDetails, permissions])

    const handleBackButton = React.useCallback(() => {
        switch (location?.pathname) {
            case '/user-details':
                return true;
            case '/view-queries':
                return true;
            case "/query-requests-details" :
                return true;   
            default:
                return false;
        }
    }, [location])

    const searchbarEnabled = React.useCallback(() =>
        location?.pathname !== '/query-editor' &&
        location?.pathname !== '/query-requests-details' &&
        (!!permissions?.length && permissions?.find((permission) => permission?.url === location?.pathname)?.url)
        , [location, permissions])

    return (<Toolbar sx={{ pr: '24px' }} >
        {handleBackButton() && <Link to={getTitle() === 'Existing Users' ? '/existing-users' : getTitle() === 'Queries' ? '/query-editor' : -1}>
            <Button variant="btn-prev-page" src={`${process.env.REACT_APP_AWS_IMAGE_BASE_URL}/${process.env.REACT_APP_S3_BUCKET_VERSION}/arrow-left.svg`} text=""></Button>
        </Link>
        } <Typography data-testid="appbar-menu"
            className={clsx(classes.textDark, classes.text24, classes.textBold)}
            component="h1"
            variant="h6"
            color="#31314F"
            noWrap
            sx={{ flexGrow: 1, textAlign: location?.pathname === '/user-details' ? 'center' : 'left' }}>
            {getTitle()}
        </Typography>
        {searchbarEnabled() && <Search location={location} />}
        <Notifications socket={socket}  navigate={navigate}  />
        <SettingsMenu location={location} handleOpenSnackMessage={handleOpenSnackMessage} navigate={navigate} />
    </Toolbar>);
}
CustomAppBar.propTypes = {
    open: propTypes.bool,
    handleOpenSnackMessage: propTypes.func,
    location: propTypes.object,
    socket: propTypes.object,
};

CustomAppBar.defaultProps = {
    open: false,
    handleOpenSnackMessage: () => ({ open: false, type: "error", message: '' }),
    location: { pathname: '/' },
    socket: {}
}