import React from 'react';
import propTypes from 'prop-types';
import { Dialog, DialogContent } from '../../../theme/MUIImports';
import { useStyles } from './styles';

export const AlertDialog = ({ isOpenPopup, showCloseButton }) => {
    const classes = useStyles();
    const { open, children, handleNo } = isOpenPopup;
    const handleClose = () => handleNo();

    return (
        <div>
            <Dialog
                className={classes.Dialog}
                open={open}
                onClose={showCloseButton ? handleClose : null}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent sx={{ p: 0 }}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}

AlertDialog.propTypes = {
    isOpenPopup: propTypes.shape({
        open: propTypes.bool,
        children: propTypes.element,
        handleNo: propTypes.func,
        showCloseButton: propTypes.bool
    })
}

AlertDialog.defaultProps = {
    isOpenPopup: {
        open: false,
        children: null,
        handleNo: () => null,
        showCloseButton: true
    }
}