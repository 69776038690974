import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    userMenu: {
        width: '100%',
        color: 'white',
        overflow: 'hidden',
        overflowY: 'auto',
        '& .MuiListItemButton-root': {
            // padding :   20,
            // ICON
            '&:hover': {
                background: 'rgb(40, 40, 56)',
            },
            '& .MuiListItemIcon-root': {
                minWidth: 'unset',
                marginRight: 14,
                '& .MuiSvgIcon-root': {
                    '& path': {
                        fill: theme.palette.light.main,
                    }
                }
            },
            // TEXT
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    fontSize: 16,
                    fontWeight: '500',
                    color: theme.palette.light.main,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '0.5rem',
                }
            }
        },
        '& .Mui-selected': {
            // ICON
            '& .MuiListItemIcon-root': {
                minWidth: 'unset',
                marginRight: 14,
                '& .MuiSvgIcon-root': {
                    '& path': {
                        fill: '#ffffff !important',
                    }
                }
            },
            // TEXT
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    fontSize: 16,
                    fontWeight: '500',
                    color: '#ffffff !important',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '0.5rem',
                }
            }
        },
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.6em',
        },
        '&::-webkit-scrollbar-track': {
           
            backgroundColor:"#31314F",
        },
        '&::-webkit-scrollbar-thumb': {
            background:'white',
            borderRadius:10
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background:'white'
        }
    },
    subMenus: {
        '& .MuiListItemButton-root': {
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '0.5rem',
                }
            }
        }
    },
    childMenu: {
        paddingLeft: '0.5rem !important',
        '& .MuiListItemButton-root': {
            '& .MuiListItemText-root': {
                '& .MuiTypography-root': {
                    fontSize: 14,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                }
            }
        }
    }
}))
