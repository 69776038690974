import React, { Suspense, useState } from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Loader, SnackMessage } from '../components';
import { PrivateRoute } from '../components/AuthGuard';
import { lightTheme } from '../theme';
import { NotFound } from '../views/NotFound';

const DashBoard = React.lazy(() => import('../views/Dashboard'));
const Login = React.lazy(() => import('../views/Login'));
const NoAccessPage = React.lazy(() => import('../views/NoAccess'));
const Authenticate = React.lazy(() => import('../views/Authenticate'));

export const AppRoutes = () => {
    const { userData } = useSelector((state) => state.auth)
    const { _id, has_access } = userData;
    const [loader] = useState(true)
    const [snackMessages, setSnackMessages] = useState({
        open: false,
        type: "",
        message: ""
    })

    const handleOpenSnackMessage = (newMessages) => setSnackMessages({ ...newMessages })

    const closeSnackMessage = () => setSnackMessages({ ...snackMessages, open: false })

    const CheckUserhasAccess = ({ children }) =>( _id && !has_access) ? children : <Navigate to="/login" />

    return (
        <div className='routes'>
            <SnackMessage snackMessages={snackMessages} closeSnack={closeSnackMessage} />
            <BrowserRouter>
                <Suspense fallback={<Loader open={loader} backgroundColor={lightTheme.palette.loader.primary} />}>
                    <Routes>
                        <Route path="/*" element={<PrivateRoute><DashBoard handleOpenSnackMessage={handleOpenSnackMessage} /></PrivateRoute>} />
                        <Route path="/login" element={<Login />}></Route>
                        <Route path="/no-access" element={<CheckUserhasAccess> <NoAccessPage handleOpenSnackMessage={handleOpenSnackMessage} /> </CheckUserhasAccess>} />
                        <Route path="/authenticate" element={<Authenticate handleOpenSnackMessage={handleOpenSnackMessage} />} />
                        <Route path="/page-not-found" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter >
        </div>
    )
}

