import { makeStyles } from "../../../theme/MUIImports";

export const useStyles = makeStyles((theme) => ({
  sidebar: {
    minHeight: '100vh',
    overflow: 'auto',
    '& .MuiPaper-root': {
      minHeight: '100vh'
    }
  },
  sidebarTop: {
    marginTop: 10,
    padding: '30px 20px',
    '& img': {
      width: 168,
      minWidth: 50,
      marginBottom: 60,
    },
    '& button.MuiButtonBase-root': {
      marginBottom: 30,
    }
  }
}));