import { SEARCH_BEGINS } from "../constants"

const initialStates = {
    search: {
        type: "",
        text: ""
    },
}

export const searchReducer = (state = initialStates, action) => {
    switch (action.type) {
        case SEARCH_BEGINS:
            return { ...state, searchLoader: true, search: action.data }
     
        default:
            return { ...state }

    }
}