import {
    APPROVE_REJECT_QUERY_REQUEST_BEGINS, APPROVE_REJECT_QUERY_REQUEST_FAILS,
    APPROVE_REJECT_QUERY_REQUEST_SUCCESS, GET_QUERY_REQUEST_DETAILS_BEGINS, GET_QUERY_REQUEST_DETAILS_FAILS,
    GET_QUERY_REQUEST_DETAILS_SUCCESS
} from "../constants";

const managerState = {
    queryDetailsLoader: false,
    queryRequestDetails: {
        data: [],
        totalCount: 0
    },
    manageQueryRequestLoader: false
}

export const managerReducer = (state = managerState, action) => {
    switch (action.type) {
        case GET_QUERY_REQUEST_DETAILS_BEGINS:
            return { ...state, queryDetailsLoader: true }
        case GET_QUERY_REQUEST_DETAILS_SUCCESS:
            return {
                ...state, queryDetailsLoader: false,
                queryRequestDetails:  action.data
                   
                
            }
        case GET_QUERY_REQUEST_DETAILS_FAILS:
            return { ...state, queryDetailsLoader: false }
        case APPROVE_REJECT_QUERY_REQUEST_BEGINS:
            return { ...state, manageQueryRequestLoader: true }
        case APPROVE_REJECT_QUERY_REQUEST_SUCCESS:
            return { ...state, manageQueryRequestLoader: false }
        case APPROVE_REJECT_QUERY_REQUEST_FAILS:
            return { ...state, manageQueryRequestLoader: false }

        default:
            return { ...state }

    }
}