import { makeStyles } from '../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    'btn-primary-outlined': {
        maxWidth     : 600,
        '& .MuiDialogContent-root': {
            padding: '12px 16px',
            '& .MuiDialogContentText-root': {
                margin: '10px 0',
                // INPUT LABEL
                '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl ': {
                    color: '#858585',
                    fontSize: 12,
                },
                // INPUT FIELD
                '& .MuiInputBase-root.MuiInputBase-formControl': {
                    '& .MuiInputBase-input': {
                        fontSize: 12,
                        height: 19,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#D9D9D9',
                        color: '#858585',
                        fontSize: 12,
                    }
                },
                "& .MuiFormHelperText-root": {
                    maxWidth: 'max-content',
                    margin  : '2px 0px 0px',
                    fontSize: 11,
                    position    : 'absolute',
                    bottom  : -20,
                    whiteSpace  : 'nowrap'
                }

            }
        },
        '& .MuiDialogActions-root': {
            borderTop: '1px solid #E0E0E0',
            padding: '8px 16px'
        }
    },
    modalHeader: {
        color: '#31314F',
        fontSize: '14px !important',
        fontWeight: '600 !important',
        textAlign: 'center',
        borderBottom: '1px solid #E0E0E0',
    },
    formControl: {
        backgroundColor: '#FAFAFA',
        borderRadius: 4,
        color: '#858585',
        boxShadow: 'none',
    },
}));
