
import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    // Search Input
    inputGroup: {
        width: 447,
        marginRight: 20,
        minWidth: 300,
        marginBottom: 2,
        position: 'relative',
        marginLeft:'auto',
        '& img': {
            width: 24,
            minWidth: 24,
            position: 'absolute',
            left: 8,
            marginTop: '12px !important',
        },
        '& .MuiFormControl-root': {
            // INPUT LABEL
            '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl ': {
                color: '#6F6F84',
                fontSize: 14,
                fontWeight: '500',
                left: 22,
                maxWidth: 'calc(100% - 46px)',
            },
            '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink ': {
                left: 0,
            },
            // INPUT FIELD
            '& .MuiInputBase-root.MuiInputBase-formControl': {
                borderRadius: 0,
                '& .MuiInputBase-input': {
                    fontSize: 14,
                    height: 17,
                    fontWeight: '500',
                    paddingLeft: 36,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#D9D9D9',
                    color: '#6F6F84',
                    fontSize: 14,
                    fontWeight: '500'
                }
            }
        },
    }
}));