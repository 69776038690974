
import { api } from "../../utils/api";
import { LOGIN_BEGINS, LOGIN_FAILS, LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_FAILS, LOGOUT_SUCCESS, SAVE_USER_DATA_SUCCESS } from "../../constants"
import { encrypt } from "../../utils/crypto";

const loginBegins = () => ({ type: LOGIN_BEGINS })
const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data })
const loginFails = () => ({ type: LOGIN_FAILS })

const saveLoginData = (data) => ({ type: SAVE_USER_DATA_SUCCESS, data })

const logoutBegins = () => ({ type: LOGOUT_BEGINS })
const logoutSuccess = () => ({ type: LOGOUT_SUCCESS })
const logoutFails = () => ({ type: LOGOUT_FAILS })

export const actionLogOut = (data) => async (dispatch) => {
    try {
        dispatch(logoutBegins)
        const res = await api.post('users/log-out', data);
        if (res.status === 200) {
            const { data, message } = res.data;
            localStorage.removeItem("userData")
            dispatch(logoutSuccess(data))
            return { status: true, message: message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(logoutFails())
        return { status: true, message: message }
    }
}

export const actionCheckisAuthenticated = () => async (dispatch) => {
    try {
        dispatch(loginBegins())
        const res = await api.get('users/login/success');
        if (res.status === 200) {
            const { data, message } = res.data;
            const { role } = data;
            dispatch(loginSuccess(data))
            dispatch(saveLoginData(data))
            const encryptData = encrypt(JSON.stringify(data));
            localStorage.setItem("userData", encryptData);
            api.defaults.headers.common["Authorization"] = `Bearer ${data?.auth_token}`
            return { status: true, message: message, role }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(loginFails())
        return { status: false, message: message }
    }
}