import { configureStore } from '@reduxjs/toolkit'
import { authReducer } from '../reducers/authReducer'
import { userReducer } from '../reducers/userReducer'
import { adminReducer } from '../reducers/adminReducer'
import { searchReducer } from '../reducers/searchReducer'
import { managerReducer } from '../reducers/managerReducer'

export default configureStore({
    reducer: {
        auth: authReducer,
        user:userReducer,
        admin:adminReducer,
        search:searchReducer,
        manager:managerReducer
    }
})