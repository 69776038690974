import * as React from 'react';
import propTypes from 'prop-types';
import { Badge, Box, IconButton, MenuItem } from "../../../theme/MUIImports"
import { CloseIcon, NotificationsIcon } from '../../../theme/MUIIcons';
import { StyledMenu, useStyles } from './styles';
import { Button, Typography } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import { actionFetchNoificationsSuccess } from '../../../actions/user';
import moment from 'moment';

export const Notifications = ({ socket, navigate }) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { userData } = useSelector(state => state?.auth);
    const { notifications } = useSelector(state => state?.user);
    const { auth_token } = userData;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleOpenNotificationMenu = (event) => {
        notifications?.data?.length && socket.emit('read', auth_token)
        setAnchorEl(event.currentTarget);
    }

    const clearAll = () => {
        notifications?.data?.length && socket.emit('remove_all', auth_token);
        dispatch(actionFetchNoificationsSuccess({
            notifications: {
                data: [],
                count: 0
            }
        }))
    }

    const handleRemove = ({ _id }) => {
        socket.emit('remove', _id);
        dispatch(actionFetchNoificationsSuccess({
            notifications: {
                data: notifications.data?.filter((menu) => menu?._id !== _id),
                count: notifications?.count > 0 ? notifications?.count - 1 : 0
            }
        }))
    }

    const handleSelectNotification = ({ _id, notification_type }) => {
        socket.emit('viewed', _id)
        // redirect to pages on notification click
        dispatch(actionFetchNoificationsSuccess({
            notifications: {
                data: notifications.data?.map((menu) => ({ ...menu, isViewed: Boolean(menu?._id === _id) })),
                count: notifications?.count
            }
        }))

        const pages = {
            QUERY_REQUEST: '/query-requests',
            USER_REQUEST: '/user-request',
            MY_REQUEST: '/my-requests'
        }
        navigate(pages[notification_type])
    }

    const handleClose = () => {
        dispatch(actionFetchNoificationsSuccess({
            notifications: {
                data: notifications.data?.map((menu) => ({ ...menu, isRead: true })),
                count: 0
            }
        }))
        setAnchorEl(null);
    }

    const customDateParse = (date) => moment(date).format('MM/DD/YY h:mmA')

    const NotificationItem = ({ title, message, time, disabled, onClick, selected, onRemove }) =>
    (

        <MenuItem disabled={disabled} selected={selected} disableRipple>
            <Box display="flex" flexDirection="column" width="100%">
                <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                    <Box display="flex" onClick={onClick} flexDirection="column" p={2}>
                        <Typography noWrap sx={{ color: 'primary.main' ,whiteSpace: 'break-spaces' }} variant="body2" text={title} />
                        <Typography sx={{ whiteSpace: 'initial !important' }} variant="caption" text={message} />

                    </Box>
                    {message && <IconButton onClick={onRemove} className={classes.closeIcon} size="small" color="error"><CloseIcon /></IconButton>}
                </Box>
                <Box display="flex" jutifyContent="flex-end">
                    <Typography noWrap variant="caption" sx={{ ml: 'auto' }} text={time} />
                </Box>
            </Box>
        </MenuItem>
    );


    return (
        <div className={classes.notifications}>
            <IconButton
                size="large"
                aria-label={`show ${notifications?.count} new notifications`}
                id="notifications-button"
                aria-controls={open ? 'notifications-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleOpenNotificationMenu}
                color="inherit">
                <Badge badgeContent={notifications?.count} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <StyledMenu
                id="notifications-menu"
                MenuListProps={{ 'aria-labelledby': 'notifications-button' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <Box display="flex" flexDirection="row" jutifyContent="flex-end">
                    {!!notifications?.data?.length && <Button text="clear all" sx={{ ml: 'auto', fontSize: 11 }} variant="text" onClick={clearAll} color="primary" />}
                </Box>
                {!!notifications?.data?.length ? notifications?.data?.map((notification) =>
                    <NotificationItem
                        selected={!notification?.isRead}
                        title={notification?.title}
                        disabled={false}
                        onRemove={() => handleRemove(notification)}
                        message={notification?.message}
                        onClick={() => handleSelectNotification(notification)}
                        time={customDateParse(notification?.createdAt)}
                        key={notification?._id} />
                ) : (
                    <NotificationItem
                        disabled={false}
                        selected={false}
                        title={'No New Notifications'}
                        message={''}
                        onClick={() => null}
                        time={''} />
                )}
            </StyledMenu>
        </div>
    );
}

Notifications.propTypes = {
    socket: propTypes.object,
    navigate: propTypes.func,
};
Notifications.defaultProps = {
    socket: {},
    navigate: () => null,
}