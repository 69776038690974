import React from 'react'
import { Button, Link, Typography } from '../../components';
import { Box, Grid } from "../../theme/MUIImports"

export const NotFound = () => {
    return (
        <Grid container spacing={2} sx={{ mt: 0, height: "100vh" }}>
            <Grid item xs={false} sm={1} sx={{ mt: 0, p: '0px !important' }}>
            </Grid>
            <Grid item xs={12} sm={11} sx={{ mt: 0, p: '0px !important' }} display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection='column' justifyContent="center">
                    <Typography text="PAGE NOT FOUND - 404" className="title-32-primary" />
                    <Typography alignItems="center" text="The entered url is not found!" className="subtitle-primary-20" />
                    <Link margin="auto" to={-1} >
                        <Button color="primary" size="small" sx={{ width: 'fit-content', margin: '10px auto' }} variant={"contained"} text={'Back'}></Button>
                    </Link>
                </Box>
            </Grid>
        </Grid>
    )
}
