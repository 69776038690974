import React from 'react';
import propTypes from 'prop-types';
import { Backdrop, CircularProgress } from '../../../theme/MUIImports';

export const LoadereBackdrop = ({ open, closeLoader, backgroundColor }) => {
    const handleClose = () => closeLoader();
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: backgroundColor }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

LoadereBackdrop.defaultProps = {
    open: false,
    backgroundColor:null,
    closeLoader:()=>null
}

LoadereBackdrop.propTypes = {
    open: propTypes.bool,
    closeLoader: propTypes.func,
    backgroundColor: propTypes.string
}