import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import propTypes from 'prop-types'

export const PrivateRoute = ({ children }) => {
    const { userData } = useSelector((state) => state.auth)
    const { _id, has_access } = userData;

    return (
        <>{_id && has_access ? children : !has_access && _id ? <Navigate to="/no-access" /> : <Navigate to="/login" />}</>
    )
}

PrivateRoute.propTypes = {
    children: propTypes.element
}

PrivateRoute.defaultProps={
    children:<></>
}