import { makeStyles } from '../../../theme/MUIImports'
export const useStyles = makeStyles((theme) => ({
    textDark: {
        color: theme.palette.primary.main,
    },
    text24: {
        fontSize: '24px !important',
    },
    textBold: {
        fontWeight: '700 !important'
    },
    maxWidth: {
        maxWidth: 'fit-content !important'
    },
    chip: {
        padding: '3px 2px  !important',
        height: 'auto  !important',
        fontSize: 11 + ' !important',
        textTransform: 'uppercase !important'
    }
}));

