import * as React from 'react';
import propTypes from "prop-types";
import { TextField, Autocomplete } from '../../../theme/MUIImports';

export const CustomAutoComplete = ({ label, options, onChange, value, error, errorMessage, keyWordChange, loader }) => {
    return (
        <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ pt: 5 }}
            onChange={onChange}
            getOptionLabel={(option) => option.name}
            value={value?.name}
            loading={loader}
            options={options}
            ListboxProps={{ sx: { maxHeight: 350 } }}
            renderInput={(params) =>
                <TextField error={error} onChange={keyWordChange} helperText={errorMessage}
                    size="small"  {...params} label={label} />
            }
        />);
}

CustomAutoComplete.propTypes = {
    label: propTypes.string,
    options: propTypes.array,
    onChange: propTypes.func,
    value: propTypes.object,
    error: propTypes.bool,
    errorMessage: propTypes.string,
    keyWordChange: propTypes.func,
    loader: propTypes.bool,
}

CustomAutoComplete.defaultProps = {
    label: 'Choose a option',
    options: [],
    onChange: (event, value, reason, details) => null,
    value: {},
    error: false,
    errorMessage: "",
    keyWordChange: (e) => null,
    loader: false
}


