import React, { useCallback, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import propTypes from 'prop-types';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import { LicenseManager } from "ag-grid-enterprise";
import { Box, Skeleton, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useStyles } from './styles';
import { Button } from '../..';
// import { Loader } from './Loader';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENCE_KEY);

let timeout = 0;
let agGridAPI = {};
export const Table = ({ headers, colConfig, pagination, customMenu,
  paginationPageSize, datasource, rowData, rowModelType, isExternalFilterPresent, placeholderText, enableResetButton, filterMenus }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("")

  const onGridReady = useCallback((params) => {

    const allColumnIds = [];
    if (!rowData?.length) {
      agGridAPI = params.api
      params.api.setServerSideDatasource(datasource);
    }

    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    params.columnApi.autoSizeColumns(allColumnIds, params);
 //eslint-disable-next-line
  }, []);


  const onChangeSearch = (e) => {
    setSearchText(e.target.value);
    // timeout added for debounce
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      Object?.keys(agGridAPI)?.length && agGridAPI.setFilterModel({ search: { filter: e.target.value } })
      setSearchText(e.target.value);
    }, 500);

  }

  const overlayNoRowsTemplate = "No record found";

  const clearFilters = useCallback(() => {
    agGridAPI.setFilterModel(null);
    setSearchText('');
  }, []);

  const Loader = () => {
    return (
      <Box display="flex" flexDirection="row" sx={{ m: 1 }} justifyContent="space-evenly" width="100%">
        <Skeleton variant="rounded" style={{ margin: '0 2px' }} width={'100%'} height={40} />
      </Box>
    );
  }

  const loadingCellRenderer = React.useMemo(() => {
    return Loader;
  }, []);

  const getMainMenuItems = React.useCallback((params) => {
    const parameters = Object.keys(filterMenus);
    switch (params.column.getId()) {
      case parameters?.find((e) => e === (params.column.getId())):
        return filterMenus[params.column.getId()];
      default:
        return params.defaultItems;
    }
  }, [filterMenus]);



  return (
    <div className={classes.table}>
      <div className={classes.tableHeaderAction}>
        {isExternalFilterPresent &&
          <Stack spacing={2} sx={{ width: 447, marginRight: 'auto', minWidth: 300, marginBottom: 2 }} className={classes.inputGroup}>
            <TextField
              value={searchText}
              onChange={onChangeSearch}
              InputLabelProps={{
                style: { fontWeight: 500 },
              }}
              label={placeholderText} />
            <img src={`${process.env.REACT_APP_AWS_IMAGE_BASE_URL}/${process.env.REACT_APP_S3_BUCKET_VERSION}/search-icon.svg`} alt="searh icon" />
          </Stack>
        }
        <div className={classes.contentLeftCenter}>
          {enableResetButton && <div className={classes.resetButtonSpace} >
            <Button variant="btn-secondary-contained" onClick={clearFilters} text="Reset" />
          </div>}
          {customMenu}
        </div>
      </div>
      <div className={"ag-theme-material"}>
        <AgGridReact
          columnDefs={headers}
          defaultColDef={colConfig}
          onGridReady={onGridReady}
          cacheBlockSize={10}
          pagination={pagination}
          isExternalFilterPresent={isExternalFilterPresent}
          rowData={rowData}
          rowModelType={rowModelType}
          serverSideFilterOnServer={true}
          suppressCsvExport={true}
          suppressExcelExport={true}
          serverSideInfiniteScroll={true}
          domLayout="autoHeight"
          animateRows={true}
          getMainMenuItems={getMainMenuItems}
          loadingCellRenderer={loadingCellRenderer}
          noRowsOverlayComponent={overlayNoRowsTemplate}
          paginationPageSize={paginationPageSize}
        ></AgGridReact>
      </div>
    </div>
  );
};

Table.propTypes = {
  headers: propTypes.array.isRequired,
  colConfig: propTypes.object,
  rowData: propTypes.array,
  pagination: propTypes.bool,
  paginationPageSize: propTypes.number,
  datasource: propTypes.object,
  rowModelType: propTypes.string,
  isExternalFilterPresent: propTypes.bool,
  enableResetButton: propTypes.bool,
  customMenu: propTypes.element,
  filterMenus: propTypes.object
}

Table.defaultProps = {
  headers: [],
  colConfig: {
    flex: 1,
    editable: false,
    sortable: true,
    filter: true
  },
  rowData: [],
  pagination: true,
  paginationPageSize: 20,
  datasource: {},
  rowModelType: "",
  isExternalFilterPresent: false,
  enableResetButton: false,
  customMenu: <></>,
  filterMenus: {
    query_type: [
      {
        name: 'Option 1',
        action: () => {
          console.log('You clicked Option');
        },
        icon: '<img src="https://www.ag-grid.com/example-assets/lab.png" style="width: 14px;" />',
      },
      'resetColumns', // a built in item
    ]
  }
}