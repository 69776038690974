import { makeStyles, Menu } from '../../../theme/MUIImports';
import { styled, alpha } from '@mui/material/styles';
import * as React from 'react';

export const useStyles = makeStyles((theme) => ({
    notifications: {
        margin: '0px 10px'
    },
    closeIcon: {
        height: 'fit-content',
        width: 'fit-content',
        '& .MuiSvgIcon-root': {
            margin: 'auto',
            fill: 'red'
        }
    }
}))

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 340,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:"#31314F",
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor:"#31314F",
        },
        '& .MuiMenu-list': {
            padding: '10px 0',
            maxHeight: 500
        },

        '& .MuiMenuItem-root': {
            padding: theme.spacing(1.5),
            maxWidth: 340,
            '& .MuiTypography-caption': {
                maxWidth: 400,
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces',
                display: 'flex',

            },
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));