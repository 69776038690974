import { styled, Switch } from "../../../theme/MUIImports"
import propTypes from 'prop-types';

export const CustomSwitch = styled(Switch)(({ label }) => ({
    padding: 8,
    width: 96,
    height: 42,
    borderRadius: 8,
    '& .MuiButtonBase-root': {
        '&:not(.Mui-checked) ~ .MuiSwitch-track:before': {
            display: 'none'
        },
        '&.Mui-checked ~ .MuiSwitch-track:after': {
            display: 'none'
        },
        '&.Mui-checked': {
            transform: 'translateX(48px)',
        }
    },
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: '#DDDDDD !important',
        opacity: '1 !important',
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 24,
            height: 16,
        },
        '&:before': {
            content: `"${label.no}"`,
            color: '#DE0000',
            fontSize: 9,
            fontWeight: '700',
            position: 'absolute',
            right: 45,
            top: 6,
        },
        '&:after': {

            content: `"${label.yes}"`,
            color: '#32C000',
            fontSize: 9,
            fontWeight: '700',
            position: 'absolute',
            left: 42,
            top: 6,

        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 26,
        height: 20,
        margin: 2,
        borderRadius: 8,
        color: '#31314F',
        position: 'relative',
        '&:after': {
            content: "''",
            width: 4,
            height: 12,
            borderRadius: 8,
            backgroundColor: 'white',
            position: 'absolute',
            left: 11,
            top: 4,
        },
    },
}));

CustomSwitch.propTypes = {
    label: propTypes.shape({
        yes: propTypes.string,
        no: propTypes.string
    })
}

CustomSwitch.defaultProps = {
    label: { yes: "Approve", no: "Deny" }
}