import React from 'react'
import { Link } from 'react-router-dom'
import propTypes from 'prop-types'
import { makeStyles } from "../../../theme/MUIImports"

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none"
    }
}))

export const CustomLink = ({ children, to, style }) => {
    const classes = useStyles();
    return (
        <Link to={to} style={style} className={classes.link}>{children}</Link>
    )
}

CustomLink.propTypes = {
    children: propTypes.element,
    style:propTypes.object,
    to: propTypes.oneOfType([
        propTypes.string,
        propTypes.number,
    ])
}
CustomLink.defaultProps = {
    children: null,
    to: "",
    style:{}
}

