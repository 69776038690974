import { LOGIN_BEGINS, LOGIN_FAILS, LOGIN_SUCCESS, LOGOUT_BEGINS, LOGOUT_FAILS, LOGOUT_SUCCESS, UPDATED_LAST_EXECUTED_QUERY_SUCCESS } from "../constants"
import { decrypt, encrypt } from "../utils/crypto";
let userData = localStorage.getItem('userData') && JSON.parse(decrypt(localStorage.getItem('userData')));

const authState = {
    loginLoader: false,
    userData: userData || {
        auth_token: "",
        email: "",
        has_access: null,
        name: "",
        profile_image: "",
        role: "",
        _id: null,
        isAuthenticated: false,
        history: []
    },
    logoutLoader: false
}

export const authReducer = (state = authState, action) => {
    switch (action.type) {
        case LOGIN_BEGINS:
            return { ...state, loginLoader: true }
        case LOGIN_SUCCESS:
            return { ...state, loginLoader: false, userData: action.data }
        case LOGIN_FAILS:
            return { ...state, loginLoader: false }
        case LOGOUT_BEGINS:
            return { ...state, logoutLoader: true }
        case LOGOUT_SUCCESS:
            return { ...state, logoutLoader: false, userData: { auth_token: "", email: "", has_access: null, name: "", profile_image: "", role: "", _id: null } }
        case LOGOUT_FAILS:
            return { ...state, logoutLoader: false }
        case UPDATED_LAST_EXECUTED_QUERY_SUCCESS:
            const newUserData = { ...state.userData };
            const history = [{
                query: action.data?.query_string,
                domain_id: action.data?.domain_id,
                session_id: action.data?.session_id
            }]
            newUserData['history'] = history;
            const encryptData = encrypt(JSON.stringify(newUserData));
            localStorage.setItem("userData", encryptData);
            return { ...state, userData: newUserData }
        default:
            return { ...state }
    }
}