import {
    CHOOSE_TICKET_BEGINS, CHOOSE_TICKET_FAILS, CHOOSE_TICKET_SUCCESS, CREATE_DOMAIN_AND_TICKET_BEGINS,
    CREATE_DOMAIN_AND_TICKET_FAILS, CREATE_DOMAIN_AND_TICKET_SUCCESS, EXECUTE_QUERY_BEGINS, EXECUTE_QUERY_FAILS, EXECUTE_QUERY_SUCCESS, GET_DOMAINS_LIST_BEGINS, GET_DOMAINS_LIST_FAILS,
    GET_DOMAINS_LIST_SUCCESS,
    GET_MANAGER_LIST_BEGINS,
    GET_MANAGER_LIST_FAILS,
    GET_MANAGER_LIST_SUCCESS,
    GET_NOTIFICATIONS_FAILS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_QUERY_HISTORY_BEGINS,
    GET_QUERY_HISTORY_FAILS,
    GET_QUERY_HISTORY_SUCCESS,
    REMOVE_MY_REQUEST_BEGINS,
    REMOVE_MY_REQUEST_FAILS,
    REMOVE_MY_REQUEST_SUCCESS,
    SELECT_QUERY_FROM_HISTORY_SUCCESS,
    UPDATED_LAST_EXECUTED_QUERY_SUCCESS,
    WRITE_QUERY_REQUEST_BEGINS,
    WRITE_QUERY_REQUEST_FAILS,
    WRITE_QUERY_REQUEST_SUCCESS
} from "../../constants";
import { api } from "../../utils/api";

// CHOOSE DOMAIN AND TICKET
const chooseTicketBegins = () => ({ type: CHOOSE_TICKET_BEGINS })
const chooseTicketSuccess = (data) => ({ type: CHOOSE_TICKET_SUCCESS, data })
const chooseTicketFailed = (data) => ({ type: CHOOSE_TICKET_FAILS, data })

/* CREATE TICKET AND DOMAIN */
const createDomainandTicketBegins = () => ({ type: CREATE_DOMAIN_AND_TICKET_BEGINS })
const createDomainandTicketSuccess = (data) => ({ type: CREATE_DOMAIN_AND_TICKET_SUCCESS, data })
const createDomainandTicketFails = () => ({ type: CREATE_DOMAIN_AND_TICKET_FAILS })
/* GET DOMAIN LISTS */
const getDomainListBegins = () => ({ type: GET_DOMAINS_LIST_BEGINS })
const getDomainListSuccess = (data) => ({ type: GET_DOMAINS_LIST_SUCCESS, data })
const getDomainListFails = () => ({ type: GET_DOMAINS_LIST_FAILS })
/*EXECUTE QUERY */
const executeQueryBegins = () => ({ type: EXECUTE_QUERY_BEGINS })
const executeQuerySuccess = (data) => ({ type: EXECUTE_QUERY_SUCCESS, data })
const executeQueryFails = (data) => ({ type: EXECUTE_QUERY_FAILS, data })
/* GET QUERY HSTORY */
const getQueryHistorytBegins = () => ({ type: GET_QUERY_HISTORY_BEGINS })
const getQueryHistorytSuccess = (data) => ({ type: GET_QUERY_HISTORY_SUCCESS, data })
const getQueryHistorytFails = () => ({ type: GET_QUERY_HISTORY_FAILS })
/* SELECT QUERY */
const selectQueryFromHistorySuccess = (data) => ({ type: SELECT_QUERY_FROM_HISTORY_SUCCESS, data })
/* UPDATE LAST QUERY */
const updateLastExecutedQuery = (data) => ({ type: UPDATED_LAST_EXECUTED_QUERY_SUCCESS, data })
/* WRITE QUERY REQUEST */
const writeQueryRequestBegins = () => ({ type: WRITE_QUERY_REQUEST_BEGINS })
const writeQueryRequestSuccess = (data) => ({ type: WRITE_QUERY_REQUEST_SUCCESS ,data})
const writeQueryRequestFails = () => ({ type: WRITE_QUERY_REQUEST_FAILS })
/* GET MANAGER LIST*/
const getManagerListBegins = () => ({ type: GET_MANAGER_LIST_BEGINS })
const getManagerListSuccess = (data) => ({ type: GET_MANAGER_LIST_SUCCESS, data })
const getManagerListFails = () => ({ type: GET_MANAGER_LIST_FAILS })

// NOTIFICATIONS
const fetchNoificationsSuccess = (data) => ({ type: GET_NOTIFICATIONS_SUCCESS, data })
const fetchNoificationsFailS = () => ({ type: GET_NOTIFICATIONS_FAILS })
const removeMyRequestBegins = () => ({ type: REMOVE_MY_REQUEST_BEGINS })
const removeMyRequestSuccess = () => ({ type: REMOVE_MY_REQUEST_SUCCESS })
const removeMyRequestFails = () => ({ type: REMOVE_MY_REQUEST_FAILS })


/* CHOOSE DOMAIN AND TICKET */
export const actionChooseJiraTicket = (data) => async (dispatch) => {
    try {
        dispatch(chooseTicketBegins())
        const res = await api.post('/domains/choose-ticket', data)
        if (res.status === 200) {
            const { message } = res.data;
            const result = res.data?.data || [];
            dispatch(chooseTicketSuccess({ ...data, query: result?.length ? result?.at(0)?.query : "" }))
            dispatch(updateLastExecutedQuery({ query_string: result?.length ? result?.at(0)?.query : "", domain_id: data?.domain_id, session_id: data?.ticket_id }))
            return { status: true, message }
        }
    } catch (error) {
        console.log(error,JSON.stringify(error));
        const { message } = error || { message: error?.response?.data || "Something went Wrong"} ;
        dispatch(chooseTicketFailed())
        return { status: false, message }
    }
}
/* CREATE TICKET AND DOMAIN */
export const actionCreateDomainandJiraTicket = (data, sideMenu) => async (dispatch) => {
    try {
        dispatch(createDomainandTicketBegins())
        const res = await api.post('/domains/create-ticket', data)
        if (res.status === 200) {
            const { data, message } = res.data;
            const newDomain = { ...data.domain, tickets: [data.ticket] }
            /* if no dommains, then first created domain choosed*/
            if (sideMenu?.length === 0) {
                dispatch(actionChooseJiraTicket({ domain_id: newDomain._id, ticket_id: newDomain.tickets?.at(0)?._id }))
            }
            dispatch(createDomainandTicketSuccess(newDomain))
            dispatch(updateLastExecutedQuery({ query_string: '', domain_id: newDomain._id, session_id: newDomain.tickets?.at(0)?._id }))
            return { status: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(createDomainandTicketFails())
        return { status: false, message }
    }
}
/* GET DOMAIN LISTS */
export const actionGetDomainList = (data) => async (dispatch) => {
    try {
        dispatch(getDomainListBegins())
        const res = await api.get('/domains/tickets', { params: data })
        if (res.status === 200) {
            const { data, message } = res.data
            dispatch(getDomainListSuccess(data))
            if (data?.length === 1) {
                dispatch(actionChooseJiraTicket({ domain_id: data?.at(0)?._id, ticket_id: data?.at(0)?.tickets?.at(0)?._id }))
            }
            return { status: true, message, total: data?.length }
        }
    } catch (error) {
        const { message } = error?.response?.data;
        dispatch(getDomainListFails())
        return { status: false, message }
    }
}
/* EXECUTE QUERY */
export const actionExecuteQuery = (payload) => async (dispatch) => {
    try {
        dispatch(updateLastExecutedQuery(payload))
        dispatch(executeQueryBegins())
        const res = await api.post('/query/execute-query', {...payload, query_string:encodeURI(payload?.query_string)})
        if (res.status === 200) {
            const { data, queryData } = res.data?.data;
            const { message } = res.data;
            dispatch(executeQuerySuccess({ rows: data, history: queryData }))
            return { status: true, message: !data?.length ? "Success, No data found" : message }
        }
    } catch (error) {
        const { message } = error || { message: error?.response?.data || "Something went Wrong"} ;
        const { queryData } = error?.response?.data?.data;
        dispatch(executeQueryFails({ message, details: message, history: queryData }))
        return { status: false, message, error: message }
    }

}
/* GET QUERY HISTORY */
export const actionGetQueryHistory = (data) => async (dispatch) => {
    try {
        dispatch(getQueryHistorytBegins())
        const res = await api.get('/query/queries-history', { params: data })
        if (res.status === 200) {
            const { data, message } = res.data
            dispatch(getQueryHistorytSuccess(data?.data))
            return { status: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getQueryHistorytFails())
        return { status: false, message }
    }
}
/* SELECT QUERY FROM HISTOR */
export const actionSelectQuerFromHistory = (data) => async (dispatch) => {
    dispatch(selectQueryFromHistorySuccess(data))
}
/* WRITE QUERY REQUEST*/
export const actionWriteQueryRequest = (data) => async (dispatch) => {
    try {
        dispatch(writeQueryRequestBegins())
        const res = await api.post('/query/execute-query', data)
        if (res.status === 200) {
            const { message } = res.data
            dispatch(writeQueryRequestSuccess({message}))
            return { status: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(writeQueryRequestFails())
        return { status: false, message }
    }
}
/* GET MANAGER LIST*/
export const actionGetManagerList = (data) => async (dispatch) => {
    try {
        dispatch(getManagerListBegins())
        const res = await api.get('/users/managers', { params: data })
        if (res.status === 200) {
            const { data, message } = res.data
            dispatch(getManagerListSuccess(data))
            return { status: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(getManagerListFails())
        return { status: false, message }
    }
}


export const actionFetchNoificationsSuccess = (data) => async (dispatch) => {
    try {
        dispatch(fetchNoificationsSuccess(data))
        return { status: true }
    } catch (error) {
        dispatch(fetchNoificationsFailS())
        return { status: false, }

    }
}

export const actionDeleteMyRequest = (data) => async (dispatch) => {
    try {
        dispatch(removeMyRequestBegins())
        const res = await api.delete('/query/delete-request', { params: data })
        if (res.status === 200) {
            const {  message } = res.data
            dispatch(removeMyRequestSuccess())
            return { status: true, message }
        }
    } catch (error) {
        const { message } = error?.response?.data || { message: 'Something went Wrong' };
        dispatch(removeMyRequestFails())
        return { status: false, message }
    }
}