import React from 'react';
import propTypes from "prop-types"
import { MuiAlert, Snackbar, Stack } from "../../../theme/MUIImports"
import { useStyles } from './styles';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const CustomSnackbar = ({ snackMessages, closeSnack }) => {
    const classes = useStyles();
    const { open, message, type } = snackMessages
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        closeSnack();
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose} className={classes.alertBox}>
                <Alert onClose={handleClose} severity={type} sx={{ maxWidth: '40rem' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

CustomSnackbar.propTypes = {
    snackMessages: propTypes.shape({
        open: propTypes.bool,
        closeSnack: propTypes.func,
        message: propTypes.string,
        type: propTypes.string
    })
}

CustomSnackbar.defaultProps  = {
    snackMessages: {
        open:false,
        closeSnack: ()=>null,
        message: "",
        type: ""
    }
}