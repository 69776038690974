import {
  CHOOSE_TICKET_BEGINS, CHOOSE_TICKET_FAILS, CHOOSE_TICKET_SUCCESS, CREATE_DOMAIN_AND_TICKET_BEGINS,
  CREATE_DOMAIN_AND_TICKET_FAILS, CREATE_DOMAIN_AND_TICKET_SUCCESS, EXECUTE_QUERY_BEGINS, EXECUTE_QUERY_FAILS,
  EXECUTE_QUERY_SUCCESS, GET_DOMAINS_LIST_BEGINS, GET_DOMAINS_LIST_FAILS,
  GET_DOMAINS_LIST_SUCCESS,
  GET_MANAGER_LIST_BEGINS,
  GET_MANAGER_LIST_FAILS,
  GET_MANAGER_LIST_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_QUERY_HISTORY_BEGINS,
  GET_QUERY_HISTORY_FAILS,
  GET_QUERY_HISTORY_SUCCESS,
  REMOVE_MY_REQUEST_BEGINS,
  REMOVE_MY_REQUEST_FAILS,
  REMOVE_MY_REQUEST_SUCCESS,
  SAVE_USER_DATA_SUCCESS,
  SELECT_QUERY_FROM_HISTORY_SUCCESS,
  WRITE_QUERY_REQUEST_BEGINS,
  WRITE_QUERY_REQUEST_FAILS,
  WRITE_QUERY_REQUEST_SUCCESS
} from "../constants"
import { decrypt } from "../utils/crypto";

let userData = localStorage.getItem('userData') && JSON.parse(decrypt(localStorage.getItem('userData')))

const lastAcitveQuery = !!userData?.history?.length && userData?.history[0];

const intitalState = {
  initialLoader: false,
  sideMenu: [],
  queryResponse: [],
  queryHistory: [],
  active: {
    domain: { _id: lastAcitveQuery?.domain_id || '', domain_name: lastAcitveQuery?.domain_name || '' },
    ticket: { _id: lastAcitveQuery?.session_id || '', ticket_url: lastAcitveQuery?.ticket_url || '' },
    query: lastAcitveQuery?.query || '',
  },
  domainListLoader: false,
  createDomainLoader: false,
  chooseDomainLoader: false,
  executeQueryLoader: false,
  queryHistoryLoader: false,
  queryError: {
    message: "",
    details: ""
  },
  managerListLoader: false,
  managerList: {
    totalCount: 0,
    data: []
  },
  writeQueryRequestLoader: false,
  notifications: {
    count: 0,
    data: []
  },
  writeQueryMessage:"",
  removeMyRequestLoader: false
}


const updateDOmains = (existingSideMenuArray, newSideMenuObject) => {
  // check domain is exist. if exist ticket will append inside the domian else new domain listed.
  const isDuplicate = (existingSideMenuArray?.length && existingSideMenuArray?.filter(menu => menu?.domain_name === newSideMenuObject?.domain_name)) || []
  const newTicketArray = newSideMenuObject?.tickets || [];
  const resultArray = existingSideMenuArray?.length && existingSideMenuArray?.map((menu) => {
    if (menu?.domain_name === newSideMenuObject?.domain_name) {
      return {
        ...menu,
        tickets: [...newTicketArray, ...menu.tickets]
      }
    }
    return {
      ...menu
    }
  })
  return isDuplicate?.length ? resultArray?.slice(0, 10) : [...[newSideMenuObject], ...existingSideMenuArray]?.slice(0, 10);
}

const checkDomainAndTicketExist = (activeItem, list) => {
  if (activeItem?.domain?._id) {
    return {
      ...activeItem,
      domain: activeItem?.domain?._id && list?.length && list?.find(menu => menu?._id === activeItem?.domain?._id),
      ticket: activeItem?.domain?._id && list?.length && list?.find(menu => menu?._id === activeItem?.domain?._id)?.tickets?.find(ticket => ticket._id === activeItem?.ticket?._id),
      query: activeItem?.query
    }
  } else {
    return {
      ...activeItem,
      domain: { _id: lastAcitveQuery?.domain_id, domain_name: lastAcitveQuery?.domain_name },
      ticket: { _id: lastAcitveQuery?.session_id, ticket_url: lastAcitveQuery?.ticket_url },
      query: lastAcitveQuery?.query
    }
  }
}

const checkResponseType = (arrayElements) => {
  const result =( arrayElements?.length && arrayElements.map((element) => {
    Object.keys(element).map(keys => {
      return typeof (element[keys]) === Object ? element[keys] = JSON.stringify(element[keys]) : element[keys]
    })
    return element
  })) || []

  return result
}

export const userReducer = (state = intitalState, action) => {
  switch (action.type) {
    case SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          domain: { _id: action?.data?.history?.length && action?.data?.history[0]?.domain_id, domain_name: action?.data?.history?.length && action?.data?.history[0].domain_name },
          ticket: { _id: action?.data?.history?.length && action?.data?.history[0].session_id, ticket_url: action?.data?.history?.length && action?.data?.history[0].ticket_url },
          query: (action?.data?.history?.length && action?.data?.history?.at(0)?.query) || '',
        }
      }
    case GET_DOMAINS_LIST_BEGINS:
      return { ...state, domainListLoader: true }
    case GET_DOMAINS_LIST_SUCCESS:
      return {
        ...state, domainListLoader: false,
        active: checkDomainAndTicketExist(state.active, action.data),
        sideMenu: action.data,
      }
    case GET_DOMAINS_LIST_FAILS:
      return { ...state, domainListLoader: false }
    case CHOOSE_TICKET_BEGINS:
      return { ...state, chooseDomainLoader: true }
    case CHOOSE_TICKET_SUCCESS:
      return {
        ...state,
        chooseDomainLoader: false,
        active: {
          ...state.active,
          domain: state?.sideMenu?.length && state?.sideMenu?.find(menu => menu?._id === action?.data?.domain_id),
          ticket: state?.sideMenu?.length && state?.sideMenu?.find(menu => menu?._id === action?.data?.domain_id)?.tickets?.find(ticket => ticket._id === action?.data?.ticket_id),
          query: action.data?.query
        },
        queryResponse: [],
        queryError: {
          message: "",
          details: ""
        }
      }
    case CHOOSE_TICKET_FAILS:
      return { ...state, chooseDomainLoader: false }
    case CREATE_DOMAIN_AND_TICKET_BEGINS:
      return { ...state, createDomainLoader: true }
    case CREATE_DOMAIN_AND_TICKET_SUCCESS:
      return {
        ...state, createDomainLoader: false, sideMenu: updateDOmains(state.sideMenu, action.data),
        active: {
          ...state.active,
          query: "",
          domain: action.data,
          ticket: action.data?.tickets?.at(0),
        },
        queryResponse: [],
        queryError: { message: '', details: '' },
      }
    case CREATE_DOMAIN_AND_TICKET_FAILS:
      return { ...state, createDomainLoader: false }
    case EXECUTE_QUERY_BEGINS:
      return { ...state, executeQueryLoader: true,writeQueryMessage:"" }
    case EXECUTE_QUERY_SUCCESS:
      return {
        ...state, executeQueryLoader: false,
        queryResponse: checkResponseType(action.data.rows),
        queryHistory: [...[action.data.history], ...state.queryHistory],
        queryError: { message: '', details: '' },
        active: {
          ...state.active,
          domain: { _id: action?.data?.history?.domain_id, domain_name: action?.data?.history?.domain_name },
          ticket: { _id: action?.data?.history?.ticket_id, ticket_url: action?.data?.history?.ticket_url },
          query: action?.data?.history?.query
        },

      }
    case EXECUTE_QUERY_FAILS:
      return {
        ...state, executeQueryLoader: false,
        queryError: action.data,
        queryResponse: [],
        queryHistory: [...[action.data.history], ...state.queryHistory],
        active: {
          ...state.active,
          domain: { _id: action?.data?.history?.domain_id, domain_name: action?.data?.history?.domain_name },
          ticket: { _id: action?.data?.history?.ticket_id, ticket_url: action?.data?.history?.ticket_url },
          query: action?.data?.history?.query
        },
      }
    case GET_QUERY_HISTORY_BEGINS:
      return { ...state, queryHistoryLoader: true }
    case GET_QUERY_HISTORY_SUCCESS:
      return { ...state, queryHistoryLoader: false, queryHistory: action.data }
    case GET_QUERY_HISTORY_FAILS:
      return { ...state, queryHistoryLoader: false }
    case SELECT_QUERY_FROM_HISTORY_SUCCESS:
      return {
        ...state,
        active: {
          ...state.active,
          domain: { _id: action?.data?.domain_id, domain_name: action?.data?.domain_name },
          ticket: { _id: action?.data?.ticket_id, ticket_url: action?.data?.ticket_url },
          query: action?.data?.query
        },
        queryResponse: [], queryError: { message: '', details: '' }
      }
    case GET_MANAGER_LIST_BEGINS:
      return { ...state, managerListLoader: true }
    case GET_MANAGER_LIST_SUCCESS:
      return { ...state, managerListLoader: false, managerList: action.data }
    case GET_MANAGER_LIST_FAILS:
      return { ...state, managerListLoader: false }
    case WRITE_QUERY_REQUEST_BEGINS:
      return { ...state, writeQueryRequestLoader: true,writeQueryMessage:"" }
    case WRITE_QUERY_REQUEST_SUCCESS:
      return { ...state, writeQueryRequestLoader: false ,writeQueryMessage: action.data.message}
    case WRITE_QUERY_REQUEST_FAILS:
      return { ...state, writeQueryRequestLoader: false }
    case GET_NOTIFICATIONS_SUCCESS:
      return { ...state, notifications: action.data.notifications }
    case REMOVE_MY_REQUEST_BEGINS:
      return { ...state, removeMyRequestLoader: true }
    case REMOVE_MY_REQUEST_SUCCESS:
      return { ...state, removeMyRequestLoader: false }
    case REMOVE_MY_REQUEST_FAILS:
      return { ...state, removeMyRequestLoader: false }


    default:
      return { ...state }
  }
}