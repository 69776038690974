
export const LOGIN_BEGINS = 'LOGIN_BEGINS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILS = 'LOGIN_FAILS'

export const LOGOUT_BEGINS = 'LOGOUT_BEGINS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILS = 'LOGOUT_FAILS'

export const GET_DOMAINS_LIST_BEGINS = 'GET_DOMAINS_LIST_BEGINS'
export const GET_DOMAINS_LIST_SUCCESS = 'GET_DOMAINS_LIST_SUCCESS'
export const GET_DOMAINS_LIST_FAILS = 'GET_DOMAINS_LIST_FAILS'

export const CHOOSE_TICKET_BEGINS = 'CHOOSE_TICKET_BEGINS'
export const CHOOSE_TICKET_SUCCESS = 'CHOOSE_TICKET_SUCCESS'
export const CHOOSE_TICKET_FAILS = 'CHOOSE_TICKET_FAILS'

export const CREATE_DOMAIN_AND_TICKET_BEGINS = 'CREATE_DOMAIN_AND_TICKET_BEGINS'
export const CREATE_DOMAIN_AND_TICKET_SUCCESS = 'CREATE_DOMAIN_AND_TICKET_SUCCESS'
export const CREATE_DOMAIN_AND_TICKET_FAILS = 'CREATE_DOMAIN_AND_TICKET_FAILS'

export const EXECUTE_QUERY_BEGINS = 'EXECUTE_QUERY_BEGINS'
export const EXECUTE_QUERY_SUCCESS = 'EXECUTE_QUERY_SUCCESS'
export const EXECUTE_QUERY_FAILS = 'EXECUTE_QUERY_FAILS'

export const GET_QUERY_HISTORY_BEGINS = 'GET_QUERY_HISTORY_BEGINS'
export const GET_QUERY_HISTORY_SUCCESS = 'GET_QUERY_HISTORY_SUCCESS'
export const GET_QUERY_HISTORY_FAILS = 'GET_QUERY_HISTORY_FAILS'

export const SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS'
export const SELECT_QUERY_FROM_HISTORY_SUCCESS = 'SELECT_QUERY_FROM_HISTORY_SUCCESS'

export const UPDATED_LAST_EXECUTED_QUERY_SUCCESS = 'UPDATED_LAST_EXECUTED_QUERY_SUCCESS'

export const CHANGE_USER_ACCESS_BEGINS = 'CHANGE_USER_ACCESS_BEGINS'
export const CHANGE_USER_ACCESS_SUCCESS = 'CHANGE_USER_ACCESS_SUCCESS'
export const CHANGE_USER_ACCESS_FAILS = 'CHANGE_USER_ACCESS_FAILS'

export const GET_USER_DETAILS_BEGINS = 'GET_USER_DETAILS_BEGINS'
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS'
export const GET_USER_DETAILS_FAILS = 'GET_USER_DETAILS_FAILS'

export const REMOVE_USER_FROM_APP_BEGINS = 'REMOVE_USER_FROM_APP_BEGINS'
export const REMOVE_USER_FROM_APP_SUCCESS = 'REMOVE_USER_FROM_APP_SUCCESS'
export const REMOVE_USER_FROM_APP_FAILS = 'REMOVE_USER_FROM_APP_FAILS'

export const DOWNLOAD_FILE_BEGINS = 'DOWNLOAD_FILE_BEGINS'
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS'
export const DOWNLOAD_FILE_FAILS = 'DOWNLOAD_FILE_FAILS'

export const SEARCH_BEGINS = 'SEARCH_BEGINS'

export const UPDATE_EXPORT_MENUS_SUCCESS = 'UPDATE_EXPORT_MENUS_SUCCESS'

export const WRITE_QUERY_REQUEST_BEGINS = 'WRITE_QUERY_REQUEST_BEGINS'
export const WRITE_QUERY_REQUEST_SUCCESS = 'WRITE_QUERY_REQUEST_SUCCESS'
export const WRITE_QUERY_REQUEST_FAILS = 'WRITE_QUERY_REQUEST_FAILS'

export const GET_MANAGER_LIST_BEGINS = 'GET_MANAGER_LIST_BEGINS'
export const GET_MANAGER_LIST_SUCCESS = 'GET_MANAGER_LIST_SUCCESS'
export const GET_MANAGER_LIST_FAILS = 'GET_MANAGER_LIST_FAILS'

export const CHANGE_USER_ROLE_BEGINS ='CHANGE_USER_ROLE_BEGINS'
export const CHANGE_USER_ROLE_SUCCESS ='CHANGE_USER_ROLE_SUCCESS'
export const CHANGE_USER_ROLE_FAILS ='CHANGE_USER_ROLE_FAILS'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILS = 'GET_NOTIFICATIONS_FAILS'

export const REMOVE_MY_REQUEST_BEGINS = 'REMOVE_MY_REQUEST_BEGINS'
export const REMOVE_MY_REQUEST_SUCCESS = 'REMOVE_MY_REQUEST_SUCCESS'
export const REMOVE_MY_REQUEST_FAILS = 'REMOVE_MY_REQUEST_FAILS'

export const GET_QUERY_REQUEST_DETAILS_BEGINS = 'GET_QUERY_REQUEST_DETAILS_BEGINS'
export const GET_QUERY_REQUEST_DETAILS_SUCCESS = 'GET_QUERY_REQUEST_DETAILS_SUCCESS'
export const GET_QUERY_REQUEST_DETAILS_FAILS = 'GET_QUERY_REQUEST_DETAILS_FAILS'

export const APPROVE_REJECT_QUERY_REQUEST_BEGINS ='APPROVE_REJECT_QUERY_REQUEST_BEGINS'
export const APPROVE_REJECT_QUERY_REQUEST_SUCCESS ='APPROVE_REJECT_QUERY_REQUEST_SUCCESS'
export const APPROVE_REJECT_QUERY_REQUEST_FAILS ='APPROVE_REJECT_QUERY_REQUEST_FAILS'
