import React from 'react';
import propTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '../../theme/MUIImports';
import { Button } from '..';
import { actionCreateDomainandJiraTicket } from '../../actions/user';
import { useStyles } from './styles';

const validationSchema = yup.object({
    domain_name: yup
        .string('Enter Domain')
        .min(3, 'Domain should be of minimum 3 characters length')
        .trim()
        .required('Domain is required'),
    ticket_name: yup
        .string('Enter your ticket')
        .trim()
        .min(3, 'Ticket should be of minimum 3 characters length')
        .required('Ticket is required'),
});

export const CreateDomainForm = ({ handleClose, handleOpenSnackMessage, navigate, location }) => {
    const classes = useStyles();
    const { createDomainLoader, sideMenu } = useSelector(state => state.user)
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            domain_name: '',
            ticket_name: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const res = await dispatch(actionCreateDomainandJiraTicket(values, sideMenu))
            if (res.status) {
                handleOpenSnackMessage({ open: true, type: "success", message: res?.message });
                handleClose();
                location?.pathname !=='/query-editor' && navigate('/query-editor')
            } else {
                handleOpenSnackMessage({ open: true, type: "error", message: res?.message })
            }
        },
    });

    return (
        <div className={classes['btn-primary-outlined']}>
            <DialogTitle className={classes.modalHeader} id="alert-dialog-title">ENTER DETAILS TO RUN A NEW QUERY</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <>
                    <DialogContent  >
                        <DialogContentText id="alert-dialog-URL" component={'span'}>
                            <TextField sx={{ my: 1 }}
                                value={formik.values.domain_name}
                                onChange={formik.handleChange} name="domain_name"
                                error={formik.touched.domain_name && Boolean(formik.errors.domain_name)}
                                helperText={formik.errors.domain_name && formik.errors.domain_name}
                                fullWidth id="outlined-domain_name" size='small' label="Domain URL" variant="outlined" />
                        </DialogContentText>
                        <DialogContentText component={'span'} id="alert-dialog-Jira Ticket / Jira Link" >
                            <TextField sx={{ my: 2, width: '50%' }}
                                value={formik.values.ticket_name}
                                onChange={formik.handleChange}
                                name="ticket_name"
                                error={formik.touched.ticket_name && Boolean(formik.errors.ticket_name)}
                                helperText={formik.errors.ticket_name && formik.errors.ticket_name}
                                id="outlined-Jira Link" label="JIRA Ticket /JIRA Link" size='small' variant="outlined" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {!!sideMenu?.length && <Button variant="btn-secondary-small" disabled={createDomainLoader} text="Cancel" onClick={handleClose} />}
                        <Button variant="btn-primary-small"
                            type="submit"
                            disabled={createDomainLoader}
                            text={createDomainLoader ? <CircularProgress color="inherit" size={10} /> : "Create"}
                            autoFocus />
                    </DialogActions>
                </>
            </form>
        </div>
    )
}

CreateDomainForm.propTypes = {
    handleClose: propTypes.func,
    handleOpenSnackMessage: propTypes.func
}