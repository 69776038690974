import { makeStyles } from '../../../theme/MUIImports';

export const useStyles = makeStyles((theme) => ({
    button: {
        padding: '10px 46px !important',
        fontWeight: '600 !important',
        fontSize: '1rem !important', 
        whiteSpace: 'nowrap'
    },
    voidButton: {
        minWidth: '187px',
        border: '1px solid rgba(49, 49, 79, 0.4) !important',
        height: '54px',
        borderRadius: '0.5rem !important',
        color: theme.palette.primary.main,
        textTransform: 'capitalize !important',
    },
    primaryButton: {
        backgroundColor: '#31314F !important',
        padding: '14px 26px !important',
        height: '46px',
        color: '#ffffff !important',
        borderRadius: '25px !important',
        textTransform: 'uppercase !important',
    },
    secondaryButtonmedium:{
       
        backgroundColor: '#CDCDCD !important',
        padding: '14px 26px !important',
        height: '46px',
        color: '#ffffff !important',
        borderRadius: '25px !important',
        textTransform: 'uppercase !important',
    },

    secondaryButtonSmall: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
        backgroundColor: '#CDCDCD !important',
        padding: '8px 12px !important',
        height: '30px',
        color: '#ffffff !important',
        borderRadius: '25px !important',
        textTransform: 'uppercase !important',
    },
    primaryButtonSmall: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
        backgroundColor: '#31314F !important',
        padding: '8px 12px !important',
        height: '30px',
        color: '#ffffff !important',
        borderRadius: '25px !important',
        textTransform: 'uppercase !important',
    },
    btnIcon: {
        margin: '0px 4px 0px'
    },
    voidButtonRoundedPill: {
        width: 'fit-content !important',
        padding: '10px 16px !important',
        borderRadius: '26px !important',
        border: '2px solid #FFFFFF !important',
        color: '#FFFFFF !important',
        fontSize: '12px !important',
        lineHeight: '15px !important',
        textTransform: 'none !important',
    },
    buttonGreyPill: {
        borderRadius: '26px !important',
        minHeight: '40px !important',
        height: '40px !important',
        backgroundColor: 'rgb(151, 151, 166) !important',
        color: '#ffffff !important',
        fontSize: '14px !important',
        fontWeight: '600 !important',
    },
    buttonOutLineImage: {
        borderRadius: '0px !important',
        color: '#EB9666 !important',
        border: '1px solid #DE5000 !important',
        padding: '6px 12px !important',
        fontSize: '12px !important',
        fontWeight: '700 !important',
        textTransform: 'capitalize !important'
    },
    outlinedBorderRadius50:{
        color: 'white',
        borderRadius: 50,
        border: '1px solid white',
        textTransform:'none'
    }
}));
