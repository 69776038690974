import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Avatar, Box, CircularProgress, IconButton, Menu, MenuItem } from '../../../theme/MUIImports';
import { Typography } from '../..';
import { actionLogOut } from '../../../actions/auth';
import { useStyles } from './styles';


export const SettingsMenu = ({ handleOpenSnackMessage, navigate, location }) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { userData } = useSelector((state) => state.auth)
    const { exportMenu, fileDownloadLoader } = useSelector((state) => state.admin)
    const { profile_image, name, auth_token } = userData;
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);

    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleLogout = async () => {
        const res = await dispatch(actionLogOut({ auth_token }))
        if (res.status) {
            handleOpenSnackMessage({ open: true, type: "success", message: res?.message })
            navigate('/login');
        } else {
            handleOpenSnackMessage({ open: true, type: "error", message: res?.message })
        }
    }

    const getSettingMenu = React.useCallback(() => {
        switch (location.pathname) {
            case '/existing-users':
                return [...exportMenu || [], ...[{ title: 'Logout', id: 2, handleClick: handleLogout }]]
            case '/user-activities':
                return [...exportMenu || [], ...[{ title: 'Logout', id: 2, handleClick: handleLogout }]]
            case '/query-history':
                return [...exportMenu || [], ...[{ title: 'Logout', id: 2, handleClick: handleLogout }]]
            default:
                return [{ title: 'Logout', id: 2, handleClick: handleLogout }]
        }
         //eslint-disable-next-line
    }, [location, exportMenu])

    return (
        <div className={classes.menu}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={name} src={profile_image} />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                className={classes.appMenu}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}>
                {fileDownloadLoader && <Box display="flex" className={classes.loader}>
                    <CircularProgress color='inherit' size={18} />
                </Box>}
                {getSettingMenu().map((option) => (
                    <MenuItem key={option.id} onClick={!!Object.keys(option)?.length && option?.handleClick} disabled={fileDownloadLoader}>
                        <Typography textAlign="center" text={option.title} />
                    </MenuItem>
                ))}
            </Menu>

        </div>
    )
}

SettingsMenu.propTypes = {
    handleOpenSnackMessage: propTypes.func,
    navigate: propTypes.func,
    location: propTypes.object
}

SettingsMenu.defaultProps = {
    handleOpenSnackMessage: () => ({ open: false, type: "error", message: '' }),
    navigate: () => null,
    location: { pathname: '/' }
}