import {
    CHANGE_USER_ACCESS_BEGINS, CHANGE_USER_ACCESS_FAILS, CHANGE_USER_ACCESS_SUCCESS, CHANGE_USER_ROLE_BEGINS, CHANGE_USER_ROLE_FAILS, CHANGE_USER_ROLE_SUCCESS, DOWNLOAD_FILE_BEGINS, DOWNLOAD_FILE_FAILS,
    DOWNLOAD_FILE_SUCCESS, GET_USER_DETAILS_BEGINS, GET_USER_DETAILS_FAILS, GET_USER_DETAILS_SUCCESS,
    REMOVE_USER_FROM_APP_BEGINS, REMOVE_USER_FROM_APP_FAILS,
    REMOVE_USER_FROM_APP_SUCCESS,
    UPDATE_EXPORT_MENUS_SUCCESS
} from "../constants"

const adminState = {
    changeUserAccessLoader: false,
    userDetailsLoader: false,
    userDetails: {},
    removeUserLoader: false,
    fileDownloadLoader: false,
    exportMenu: [],
    userRoleChangeLoader: false
}

export const adminReducer = (state = adminState, action) => {
    switch (action.type) {
        case CHANGE_USER_ACCESS_BEGINS:
            return { ...state, changeUserAccessLoader: true }
        case CHANGE_USER_ACCESS_SUCCESS:
            return { ...state, changeUserAccessLoader: false }
        case CHANGE_USER_ACCESS_FAILS:
            return { ...state, changeUserAccessLoader: false }
        case GET_USER_DETAILS_BEGINS:
            return { ...state, userDetailsLoader: true }
        case GET_USER_DETAILS_SUCCESS:
            return { ...state, userDetailsLoader: false, userDetails: action.data }
        case GET_USER_DETAILS_FAILS:
            return { ...state, userDetailsLoader: false }
        case REMOVE_USER_FROM_APP_BEGINS:
            return { ...state, removeUserLoader: true }
        case REMOVE_USER_FROM_APP_SUCCESS:
            return { ...state, removeUserLoader: false }
        case REMOVE_USER_FROM_APP_FAILS:
            return { ...state, removeUserLoader: false }
        case DOWNLOAD_FILE_BEGINS:
            return { ...state, fileDownloadLoader: true }
        case DOWNLOAD_FILE_SUCCESS:
            return { ...state, fileDownloadLoader: false }
        case DOWNLOAD_FILE_FAILS:
            return { ...state, fileDownloadLoader: false }
        case UPDATE_EXPORT_MENUS_SUCCESS:
            return { ...state, exportMenu: action.data }
        case CHANGE_USER_ROLE_BEGINS:
            return { ...state, userRoleChangeLoader: true }
        case CHANGE_USER_ROLE_SUCCESS:
            return { ...state, userRoleChangeLoader: false, userDetails: { ...state.userDetails, role: action.data.user_role } }
        case CHANGE_USER_ROLE_FAILS:
            return { ...state, userRoleChangeLoader: false }
        default:
            return { ...state }
    }
}