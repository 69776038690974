import axios from 'axios'
import { decrypt } from './crypto';

let token = (localStorage.getItem('userData') && JSON.parse(decrypt(localStorage.getItem('userData')))?.auth_token);

// Set config defaults when creating the instance
export const api = axios.create({ withCredentials: true, baseURL: process.env.REACT_APP_BASE_URL });
// Alter defaults after instance has been created
if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

}

api?.interceptors?.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) {
     window.location.href = "/login";
     localStorage.removeItem('userData')
  }
  return Promise.reject(error);
});