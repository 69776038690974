import React from "react"
import { Provider } from "react-redux";
import store from "./store"
import { AppRoutes } from "./AppRoutes";
import { lightTheme } from './theme/index';
import { ThemeProvider } from "./theme/MUIImports";
import './App.css';

function App() {
  return (
    <div  data-testid="app-element">
      <Provider store={store}>
        <ThemeProvider theme={lightTheme}>
          <AppRoutes />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
