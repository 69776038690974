import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { IconButton, Stack, TextField, Tooltip } from '../../../theme/MUIImports'
import { actionSearch } from '../../../actions/search';
import { useStyles } from './styles';
import { CancelIcon } from '../../../theme/MUIIcons';

export const Search = ({ location }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchText, setSearchText] = useState("")

    const onChangeSearch = (e) => {
        setSearchText(e.target.value)
        dispatch(actionSearch({ search: e.target.value }))
    }

    const handleClear = () => {
        setSearchText('')
        dispatch(actionSearch({ search: '' }))
    }

    const getLabel = useCallback(() => {
        switch (location.pathname) {
            case '/query-history':
                return "Search by query, user"
            case '/user-activities':
                return "Search by activity, user"
            case '/user-request':
                return "Search by name, email"
            case '/existing-users':
                return "Search by name, email"
            case '/view-queries':
                return "Search by query, user, domain, JIRA ticket/JIRA link"
            case '/my-requests':
                return 'Search by query, type, status and manager'
            case '/query-requests' :
                return 'Search by name, type and query'
            default:
                return 'Search...';
        }
    }, [location])

    useEffect(() => {
        setSearchText('')
        dispatch(actionSearch({ search: '' }))
      //eslint-disable-next-line
    }, [location.pathname])

    return (
        <Stack spacing={2} className={classes.inputGroup}>
            <TextField
                value={searchText}
                onChange={onChangeSearch}
                InputLabelProps={{
                    style: { fontWeight: 500 },
                }}
                InputProps={{
                    endAdornment: !!searchText?.length && (<> <IconButton onClick={handleClear} size='small'><Tooltip title='Clear'><CancelIcon color='error' /></Tooltip></IconButton></>)
                }}
                label={getLabel()} />
            <img src={`${process.env.REACT_APP_AWS_IMAGE_BASE_URL}/${process.env.REACT_APP_S3_BUCKET_VERSION}/search-icon.svg`} alt="searh icon" />
        </Stack>
    )
}
